import React,{useContext,useEffect, useState,useRef} from 'react';
import { getRequest, postRequest, putRequest,deleteRequest } from '../../api/ApiController';
import { DatabaseContext } from '../../redux/DatabaseStore';
import { ReactTabulator,reactFormatter } from 'react-tabulator';
import '../../assets/css/style.css';
import '../../assets/css/iconsmenu.css'
import { confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { AddIcon, EditIcon, CopyIcon, DeleteIcon, StepBackIcon, PrintIcon, SearchIcon, ExportIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon,EditRowIcon} from "../svg/svgIcons";
import {useReactToPrint} from "react-to-print";
import { InfinitySpin } from  'react-loader-spinner';
import { DateTime } from "luxon";
import AuthContext from '../../components/AuthContext';


export default function EmployeeList(){

    const [state,dispatch] = useContext(DatabaseContext);
    const {user} = useContext(AuthContext);

    const [stepBackIcon,setStepBackIcon] = useState(true);
    const [insertIcon,setInserticon] = useState(!user.permissions.includes("add users"));
    const [editIcon,setEditIcon] = useState(true);
    const [deleteIcon,setDeleteIcon] = useState(true);
    const [favoriteIcon,setFavoriteIcon] = useState(false);
    const [copyIcon,setCopyIcon] = useState(true);

    const [visibleSearch,setVisibleSearch] = useState(false);
    const [visibleLoading,setVisibleLoading] = useState(false);
    const [searchValue,setSearchValue] = useState('');
    const [scrollValue,setScrollValue] = useState(1);
    const [favoriteVisible,setFavoriteVisible] = useState(false);
    const [favoriteGroupValue,setFavoriteGroupValue] = useState("");
    const [favoriteValue,setFavoriteValue] = useState("");

    const [editFormUser, setEditFormUser] = useState([]);

    const ref = useRef(null);
    const toast = useRef(null);
    const elementRef = useRef(null);
    const componentRef = useRef(null);
    const handlePrint = useReactToPrint({
        onBeforeGetContent: () => {componentRef.current.style.display = "block";},
        onAfterPrint: () => {componentRef.current.style.display = "none";},
        content: () => componentRef.current,
    });


    useEffect(() => {
      if (state.users && ref.current) {
        //ref.current.updateData(state.users);
        if(ref.current.getSelectedData()==0){
          setEditIcon(true);
          setDeleteIcon(true);
          setCopyIcon(true);
        }
        //lepsie vymysliet reload
        //dat hook na to
      }
    },[state.users,ref.current]);
  
  
  function matchAny(data, filterParams) {
    //data - the data for the row being filtered
    //filterParams - params object passed to the filter
    //RegExp - modifier "i" - case insensitve

    var match = false;
    const regex = RegExp(filterParams.value, 'i');

    for (var key in data) {
        if (regex.test(data[key]) === true) {
            match = true;
        }
    }
    return match;
  }

  function searchTable(){
    ref.current.setFilter(matchAny, { value: searchValue});
    if (searchValue === " ") {
      ref.current.clearFilter()
    }
    setVisibleSearch(false);
  }

    const accept = () => {
        let rows_to_delete = [];
        rows_to_delete = ref.current.getSelectedData();
        console.log(rows_to_delete);
        rows_to_delete.forEach(element => {
          console.log(element);
          deleteRequest(`/api/users/${element.id}`)
              .then((response) => {
                let rows = ref.current.searchRows("id", "=", element.id);
                rows[0].deselect();
                rows[0].delete()
                .then(function(){
                  state.users = ref.current.getData();
                  console.log(ref.current.getData(),state.users);
                });
                toast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Používateľ bol úspešne vymazaný!" });
                /*getRequest("/api/users").then((response) => {
                  dispatch({type:'load_users',newUsers:response.data});
                  dispatch({type:'users_reload',value:true});
                  dispatch({type:'users_reload',value:false});
                });*/
              }).catch((reason) => {
                  alert(reason);
                  toast.current.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Záznam sa nepodarilo vymazať!', life: 3000 });
              })
        });
        /*getRequest("/api/users").then((response) => {
          dispatch({type:'load_users',newErrorRecords:response.data});
        });   */
        //toast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne vymazaný!" });
    }

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavka bola zrušená!', life: 3000 });
    }

    const confirm = () => {
        confirmDialog({
            message: 'Prajete si vymazať tento záznam/y?',
            header: 'Potvrdenie vymazania',
            icon: 'pi pi-info-circle',
            rejectClassName: 'p-button-danger',
            acceptLabel: 'Nie',
            rejectLabel: 'Áno',
            defaultFocus: 'accept',
            reject: accept,
            accept: reject
        });
    };

  const rowClick = (e, row) => {
    console.log(e, row);
 };

  const onSelectRow = (data, rows) => {
    setEditFormUser(data);
    setEditIcon(true);
    setCopyIcon(true);
    setDeleteIcon(true);
    if(data.length === 1){
        if(user.permissions.includes("add users"))setCopyIcon(false);
        if(user.permissions.includes("edit users"))setEditIcon(false);
    }
    if(data.length !== 0){
        let admins = [];
        admins = data.filter(selected_user => selected_user.role =="admin" || selected_user.id == user.id);
        if(user.permissions.includes("delete users") && admins.length<1)setDeleteIcon(false);
    }
  };

  function onEditRow(edit_user){
    dispatch({type:'create_new_tab',name:'Upraviť používateľa',page:'UserAdd',props:{user: edit_user,type:'edit',not_last_used: true},mask:'glo006'});
  };
  
  function EditRowFormatter(props) {
    const rowData = props.cell.getRow().getData();
    return <div className='flex items-center justify-center w-full h-full'><button type='button' onClick={() => onEditRow(rowData)}><EditRowIcon/></button></div>;
  }

  function departmentHeaderFilter(headerValue, rowValue, rowData, filterParams){
    if(rowData.priority_departments.length==0){
      if(rowData.employee.name.toLowerCase().includes(headerValue.toLowerCase()))return true;
      return false;
    }
    else{
      let departments = rowData.priority_departments.map(item => item.name);
      for(let i = 0;i<departments.length;i++){
        let filter = headerValue.toLowerCase();
        let value = departments[i].toLowerCase();
        if(value.includes(filter)){
          return true;
        }
      }
      return false;
    }
  }

  function EmployeeDepartmentFormatter(props) {
    const rowData = props.cell.getRow().getData();
    if(rowData.priority_departments.length > 0){
      return rowData.priority_departments.map((item) => item.name).join(' ');
    }
    else{
      return rowData.employee.name;
    }
  }

  const columns = [
    { formatter:"rowSelection", titleFormatter:"rowSelection", hozAlign:"center",width: 20,headerSort:false,resizable:false},
    { hozAlign:"center",width: 20,headerSort:false,formatter: reactFormatter(<EditRowFormatter/>),resizable:false},
    { title: 'Prihlasovacie meno', field: 'username',headerFilter:"input",resizable:false},
    { title: 'Rola', field: 'role',headerFilter:"input",resizable:false},
    { title: 'Priradené k', field: 'employee.name',headerFilter:"input",headerFilterFunc:departmentHeaderFilter,formatter: reactFormatter(<EmployeeDepartmentFormatter/>),resizable:false},
  ];

  async function scrollToFirst(){
    let res = state.users[0].id;
    ref.current.scrollToRow(res, "top", true);
    setScrollValue(1);
  }

  async function scrollUp(){
    let visible_rows = Math.floor((elementRef.current.clientHeight-68)/30-1);
    if((scrollValue-visible_rows+1) >= 0){
      let res = state.users[scrollValue-visible_rows+1]?.id;
      ref.current.scrollToRow(res, "top", true);
      setScrollValue(scrollValue-visible_rows);
    }
    else if(scrollValue > 0 && scrollValue <= visible_rows){
      let res = state.users[0]?.id;
      ref.current.scrollToRow(res, "top", true);
      setScrollValue(1);
    }
  }

  async function scrollDown(){
    let visible_rows = Math.floor((elementRef.current.clientHeight-68)/30-1);
    let rows = ref.current.getRows(true).length;
    if(scrollValue===1){
      let res = state.users[scrollValue+visible_rows-1]?.id;
      ref.current.scrollToRow(res, "top", true);
      setScrollValue(scrollValue+visible_rows);
    }
    else if((scrollValue+visible_rows) <= rows){
      let res = state.users[scrollValue+visible_rows-1]?.id;
      let rows = ref.current.getRows(true).length;
      setScrollValue(scrollValue+visible_rows);
      if(res > rows-visible_rows){
        res = state.users[rows-visible_rows]?.id;
        setScrollValue(rows-visible_rows);
      }
      ref.current.scrollToRow(res, "top", true);
    }
  }

  async function scrollToLast(){
    let visible_rows = Math.floor((elementRef.current.clientHeight-68)/30-1);
    let rows = ref.current.getRows(true).length;
    let res = state.users[rows-visible_rows]?.id;
    ref.current.scrollToRow(res, "top", true);
    setScrollValue(rows-visible_rows);
  }
  function addNewUser(){
    dispatch({type:'create_new_tab',name:'Nový používateľ',page:'UserAdd',props:{user: null,type:'add'},mask:'glo006'})
  }
  function copyUser(){
    dispatch({type:'create_new_tab',name:'Nový používateľ',page:'UserAdd',props:{user: ref.current.getSelectedData()[0],type:'copy',not_last_used: true},mask:'glo006'})
  }
  function editUser(){
    dispatch({type:'create_new_tab',name:'Upraviť používateľa',page:'UserAdd',props:{user: ref.current.getSelectedData()[0],type:'edit',not_last_used: true},mask:'glo006'})
  }

  function confirmFavoriteDialog(){

  }

    return(
      <>
      <div className='w-full h-10 flex flex-row bg-[#333]'>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertIcon} onClick={() => addNewUser()}><AddIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={editIcon} onClick={() => editUser()}><EditIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={copyIcon} onClick={() => copyUser()}><CopyIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={confirm} disabled={deleteIcon}><DeleteIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button onClick={() => ref.current.print(false, true)}><PrintIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button onClick={() => setVisibleSearch(true)}><SearchIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button onClick={() => ref.current.download("xlsx", "data.xlsx", {sheetName:"MyData"})}><ExportIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button onClick={() => scrollToFirst()}><BackToFirstIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button onClick={() => scrollUp()}><BackIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button onClick={() => scrollDown()}><ForwardIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button onClick={() => scrollToLast()}><ForwardToLastIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={favoriteIcon} onClick={() => setFavoriteVisible(true)}><FavoriteIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button><PriceIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
      </div>
<div className='px-1 bg-white rounded-md shadow-md'>
<div className="flex flex-col overflow-x-auto no-scrollbar min-h-[calc(100vh-177px)]">
<div className="sm:-mx-6 lg:-mx-8 overflow-hidden">
  <div className="inline-block w-full py-2 sm:px-6 lg:px-8" >
    <div ref={elementRef}>
      <ReactTabulator
      data={state.users} 
      className='h-[calc(100vh_-_193px)]' //176px
      onRef={(r) => (ref.current = r.current)}
      columns={columns}
      events={{
        rowClick: rowClick,
        rowSelectionChanged: onSelectRow, 
      }}
      options={{renderHorizontal:"virtual",movableColumns: true,
      printAsHtml:true,printHeader:"<h1>Zoznam zamestnancov<h1>",printRowRange:"active"}}/> 
    </div> 
  </div> 
</div> 
</div>
<Toast ref={toast} />
          <Dialog header="Hľadaj v zozname:" visible={visibleSearch} style={{ width: '40vw' }} onHide={() => setVisibleSearch(false)}>
            <div className="flex flex-columns">
              
              <input
                  value={searchValue} onChange={e => setSearchValue(e.target.value)}
                  type="text"
                  className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
              />
              <button onClick={() => searchTable()} className='w-16 ml-8 px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'><SearchIcon/></button>
            </div>
          </Dialog>
    <Dialog header="Pridať do skupiny:" visible={favoriteVisible} style={{ width: '40vw' }} onHide={() => setFavoriteVisible(false)}>
      <div className="flex flex-columns">
        <div className="flex-row">
        <select 
        value={favoriteGroupValue} onChange={e => setFavoriteGroupValue(e.target.value)}
        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
          <option  value="">Vyberte možnosť</option> 
          {state.favorites.map((item) => (
            <option key={item.id} value={item.id}>{item.name}</option>
          ))}  
          <option  value="nova">Vytvoriť novú</option>               
        </select>
        {favoriteGroupValue=="nova" && <input
                  value={favoriteValue} onChange={e => setFavoriteValue(e.target.value)}
                  type="text"
                  placeholder="Názov skupiny..."
                  className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
              />}
              </div>
        <button onClick={() => confirmFavoriteDialog()} className='w-22 h-10 ml-8 mt-2 px-4 py-2 text-center tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'>ULOŽIŤ</button>
      </div>
    </Dialog>
      <Dialog visible={visibleLoading} style={{ width: '25vw',height:'25vh',backgroundColor: 'transparent',boxShadow: 'none'}} className='mypanel flex flex-row'  closable={false} draggable={false} showHeader={false}>
          <InfinitySpin 
          width='208'
          color="#000"
          />
          <p className='text-2xl font-bold text-center w-52 text-black'>Načítavam...</p>
      </Dialog>
      </div> 
 </>
    )

}