import React,{useContext,useEffect, useState, useRef} from 'react';
import { DatabaseContext } from '../../redux/DatabaseStore';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { deleteRequest, getRequest, postRequest } from '../../api/ApiController';
import { confirmDialog } from 'primereact/confirmdialog';
import { SaveIcon, AddIcon, EditIcon, CopyIcon, DeleteIcon, StepBackIcon, StepForwardIcon, PrintIcon, SearchIcon, ExportIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, SaveAndCloseIcon} from "../svg/svgIcons";
import {useReactToPrint} from "react-to-print";
import { Dialog } from 'primereact/dialog';
import { InfinitySpin } from  'react-loader-spinner';
import { Checkbox } from "primereact/checkbox";

export default function UserAdd({user,type}){

    const [state,dispatch] = useContext(DatabaseContext);

    const [formUser,setFormUser] = useState(user);
    const [userType, setUserType] = useState(type);
    const [accountEmail,setAccountEmail] = useState(user?.employee?.email);

    const [stepBackIcon,setStepBackIcon] = useState(true);
    const [scrollIcons,setScrollIcons] = useState(true);

    const [visibleLoading,setVisibleLoading] = useState(false);
    const [favoriteVisible,setFavoriteVisible] = useState(false);
    const [favoriteGroupValue,setFavoriteGroupValue] = useState("");
    const [favoriteValue,setFavoriteValue] = useState("");

    const [visiblePassword,setVisiblePassword] = useState(false);
    const [newPassword,setNewPassword] = useState("");

    const [closeTab,setCloseTab] = useState(false);

    const [priorityDepartments, setPriorityDepartments] = useState((user?.priority_departments!=null)?user.priority_departments.map((dep) => dep.id):[]);

    const onDepartmentsChange = (e) => {
        let _departments = [...priorityDepartments];

        if (e.checked)
            _departments.push(e.value);
        else
            _departments.splice(_departments.indexOf(e.value), 1);
            setPriorityDepartments(_departments);
    }

    const toast = useRef(null);
    const submitRef = useRef(null);
    const componentRef = useRef(null);
    const handlePrint = useReactToPrint({
        onBeforeGetContent: () => {componentRef.current.style.display = "block";},
        onAfterPrint: () => {componentRef.current.style.display = "none";},
        content: () => componentRef.current,
    });

    function randomString(len, charSet) {
        charSet = charSet || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var randomString = '';
        for (var i = 0; i < len; i++) {
            var randomPoz = Math.floor(Math.random() * charSet.length);
            randomString += charSet.substring(randomPoz,randomPoz+1);
        }
        return randomString;
    }

    useEffect(() => {
        // Update the document title using the browser API
        if(userType=="edit"){
            setScrollIcons(false);
        }
    },[userType]);

    const userFormikValidate = values => {
        const errors = {};
        if (values.username.length<5) {
            errors.username = '*Prihlasovacie meno je krátke';
        }
        if (!values.username) {
            errors.username = '*Povinné';
        }
        if (values.role=="") {
            errors.role = '*Povinné';
        }
        let usernames = state.users.map(user => user.username);
        if(userType==="add" && usernames.includes(values.username)){
            errors.username = '*Prihlasovacie meno je obsadené';
        }
        if(userType==="edit" && state.users.filter(user => user.id != formUser.id).map(user => user.username).includes(values.username)){
            errors.username = '*Prihlasovacie meno je obsadené';
        }
        if(values.employee_id!="" && values.employee_id!=null){
            let email = state.employees.filter(employee => employee.id == values.employee_id)[0]?.email;
            if(email=="" || email==null){
                errors.employee_id = '*Zamestnanec nemá zadaný email';
            } 
        }
        return errors;
    };

    const userformik = useFormik({
        initialValues: formUser == null?{
            id:'',username:'',role:'',employee_id:''
        }:formUser,
        enableReinitialize:true,
        validate: userFormikValidate,
        onSubmit: values => {
            if((values.employee_id==null || values.employee_id=="") && priorityDepartments.length==0){
                alert("Zadaj oddelenie/a pre používateľa");
            }
            else{
                if(values.employee_id==null || values.employee_id==""){
                    values.password = randomString(8);
                    setNewPassword(values.password);
                }
                let formData2 = new FormData();
                delete values?.priority_departments;
                if(priorityDepartments.length>0){
                    values.employee_id = "";
                    for (var i = 0; i < priorityDepartments.length; i++) {
                        formData2.append("priority_departments[]", priorityDepartments[i]);
                    }
                }
                for (let value in values) {
                    formData2.append(value, values[value]);
                }
                if(userType==="add"){
                    postRequest('/api/users', formData2,true)
                    .then((response) => {
                        setFormUser(response.data);
                        userformik.setValues(response.data);
                        getRequest("/api/users").then((response) => {
                            dispatch({type:'load_users',newUsers:response.data});
                            toast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Používateľ uložený!"});
                            setUserType("edit");
                            if(values.password!="")setVisiblePassword(true);
                            else if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        });
                    }).catch((reason) => {
                        alert("Daný úkon sa nepodaril!");
                    })
                }
                else{
                    postRequest(`/api/users/update/${userformik.values.id}`, formData2,true)
                    .then((response) => {
                        setFormUser(response.data);
                        userformik.setValues(response.data);
                        getRequest("/api/users").then((response) => {
                            dispatch({type:'load_users',newUsers:response.data});                        
                            toast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Používateľ bol úspešne upravený!" });
                            if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        });
                    }).catch((reason) => {
                        alert("Daný úkon sa nepodaril!");
                    })
                }
            }
        },
    });

    function addNewUser(){
        dispatch({type:'create_new_tab',name:'Nový zamestnanec',page:'UserAdd',props:{user: null,type:'add'},mask:'glo006'})
    }

    function copyUser(){
        dispatch({type:'create_new_tab',name:'Nový zamestnanec',page:'UserAdd',props:{user: userformik.values,type:'copy'},mask:'glo006'})
    }

    function getIndex(user_id) {
        return state.users.findIndex(obj => obj.id === user_id);
    }

    async function scrollToFirst(){
        let user = state.users[0];
        setFormUser(user);
        setPriorityDepartments(user.priority_departments.map((dep) => dep.id));
    }
    
    async function scrollUp(){
        let id = getIndex(userformik.values.id);
        if(id-1>=0){
            setFormUser(state.users[id-1]);
            setPriorityDepartments(state.users[id-1].priority_departments.map((dep) => dep.id));
        }
    }
    
    async function scrollDown(){
        let id = getIndex(userformik.values.id);
        if(id+1<=state.users.length-1){
            setFormUser(state.users[id+1]);
            setPriorityDepartments(state.users[id+1].priority_departments.map((dep) => dep.id));
        }
    }
    
    async function scrollToLast(){
        let last = state.users.length-1;
        let user = state.users[last];
        setFormUser(user);
        setPriorityDepartments(user.priority_departments.map((dep) => dep.id));
    }

    function confirmFavoriteDialog(){
        
    }

    const acceptReset = () => {
        let formData2 = new FormData();
        let resetString = randomString(8);
        console.log(userformik.values.employee_id);
        if(userformik.values.employee_id=="" || userformik.values.employee_id==null){
            setNewPassword(resetString);
            formData2.append("reset_password", resetString);
        }
        postRequest(`/api/users/reset_password/${formUser.id}`, formData2,true)
        .then((response) => {
            if(userformik.values.employee_id=="" || userformik.values.employee_id==null)setVisiblePassword(true);
            toast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Heslo bolo resetované!" });
        }).catch((reason) => {
            alert("Daný úkon sa nepodaril!");
            toast.current.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Heslo sa nepodarilo resetovať!', life: 3000 });
        })
      }
    
      const rejectResetDelete = () => {
          toast.current.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavka bola zrušená!', life: 3000 });
      }
    
    const confirmReset = () => {
        confirmDialog({
            message: 'Prajete si resetovať heslo tohto používateľa?',
            header: 'Potvrdenie resetovania',
            icon: 'pi pi-info-circle',
            rejectClassName: 'p-button-danger',
            acceptLabel: 'Nie',
            rejectLabel: 'Áno',
            accept: rejectResetDelete,
            reject: acceptReset
        });
    };

    return (
        <div>
        <div className='w-full h-10 flex flex-row bg-[#333]'>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button type='submit' onClick={() => {setCloseTab(true);submitRef.current.click();}}><SaveAndCloseIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button type='submit' onClick={() => {submitRef.current.click();}}><SaveIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button onClick={() => addNewUser()}><AddIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button onClick={() => copyUser()}><CopyIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="opacity-25"><PrintIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollToFirst()} disabled={scrollIcons}><BackToFirstIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollUp()} disabled={scrollIcons}><BackIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollDown()} disabled={scrollIcons}><ForwardIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollToLast()} disabled={scrollIcons}><ForwardToLastIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button onClick={() => setFavoriteVisible(true)}><FavoriteIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button><PriceIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
        </div>
        
        <div className="relative flex flex-col justify-left overflow-x min-h-[calc(100vh-177px)]">
          <div className=" mb-2 ">
                <div className="w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md">
                <form onSubmit={userformik.handleSubmit}>
                <h1 className="text-3xl font-semibold text-left text-zinc-900">
                   POUŽÍVATEĽ
                </h1>
                {userType=="edit" && <label className="block text-md font-semibold text-gray-800">Por.č.: {userformik.values.id}</label>}
                <div className="mt-6 md:grid grid-cols-2 gap-4 mb-2">
                    <div>
                      <label
                            htmlFor="username"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Používateľské meno
                        </label>
                        <input
                            name='username'
                            type="text"
                            onChange={userformik.handleChange} value={userformik.values.username}
                            className="block w-full px-4 py-2 mt-2 mb-4 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        {userformik.errors.username ? <div style={{color:'red'}}>{userformik.errors.username}</div> : null}
                        </div>
                        <div>
                        <label
                            htmlFor="role"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Používateľská rola
                        </label>
                        <select 
                        name='role'
                        onChange={userformik.handleChange} value={userformik.values.role}
                        className='block w-full px-4 py-2 mt-2 mb-4 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                          <option value="">Vyber rolu</option>
                          {state.roles.map((role) => (
                            <option key={role.id} value={role.name}>{role.name}</option>
                          ))}
                        </select>
                        {userformik.errors.role ? <div style={{color:'red'}}>{userformik.errors.role}</div> : null}
                        </div>   
                        <div>
                        <label
                            htmlFor="employee_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Pridelené zamestnancovi
                        </label>
                        <select 
                        name='employee_id'
                        value={userformik.values.employee_id} onChange={userformik.handleChange}
                        className='block w-full px-4 py-2 mt-2 mb-4 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                          <option value="">Oddelenie/a</option>
                          {state.employees.filter(employee => (employee.user == null && employee.date_of_departure == null) || employee.user?.id == formUser?.id).map((employee) => (
                            <option key={employee.id} value={employee.id}>{employee.name}</option>
                          ))}
                        </select>
                        {userformik.errors.employee_id ? <div style={{color:'red'}}>{userformik.errors.employee_id}</div> : null}
                        </div>
                        {(userformik.values.employee_id == null || userformik.values.employee_id == "") ? <div>
                        <label className="block text-md font-semibold text-gray-800">Oddelenia</label>
                        <div className='grid grid-cols-2'>
                            {state.priority_departments.map((department) => 
                                <div className="flex align-items-center">
                                    <Checkbox inputId={department.id} name="departments" value={department.id} onChange={onDepartmentsChange} checked={priorityDepartments.includes(department.id)} className='p-button-sm p-button-text'/>
                                    <label htmlFor={department.id} className="ml-2">{department.name}</label>
                                </div>
                            )}
                        </div></div>:<div></div>}
                        <div>
                        {userType=="edit" &&
                            <button type="button" className='w-full font-medium mb-2 px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600' onClick={() => confirmReset()}>RESETOVANIE HESLA</button>
                        }
                        </div>
                </div>  
                <button type="submit" ref={submitRef} className='hidden'></button>  
                </form>       
            </div>
            </div>
            <div>
            {/*<button type="submit" className='w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'>ULOŽIŤ</button>*/}
            </div>
        </div>
        <Toast ref={toast} />
        <Dialog header="Pridať do skupiny:" visible={favoriteVisible} style={{ width: '40vw' }} onHide={() => setFavoriteVisible(false)}>
        <div className="flex flex-columns">
          <div className="flex-row">
          <select 
          value={favoriteGroupValue} onChange={e => setFavoriteGroupValue(e.target.value)}
          className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
            <option  value="">Vyberte možnosť</option> 
            {state.favorites.map((item) => (
              <option key={item.id} value={item.id}>{item.name}</option>
            ))}  
            <option  value="nova">Vytvoriť novú</option>               
          </select>
          {favoriteGroupValue=="nova" && <input
                    value={favoriteValue} onChange={e => setFavoriteValue(e.target.value)}
                    type="text"
                    placeholder="Názov skupiny..."
                    className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                />}
                </div>
          <button onClick={() => confirmFavoriteDialog()} className='w-22 h-10 ml-8 mt-2 px-4 py-2 text-center tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'>ULOŽIŤ</button>
        </div>
        </Dialog>
        <Dialog visible={visibleLoading} style={{ width: '25vw',height:'25vh',backgroundColor: 'transparent',boxShadow: 'none'}} className='mypanel flex flex-row'  closable={false} draggable={false} showHeader={false}>
            <InfinitySpin 
            width='208'
            color="#000"
            />
            <p className='text-2xl font-bold text-center w-52 text-black'>Načítavam...</p>
            </Dialog>
            <Dialog header="Počiatočné heslo" visible={visiblePassword} style={{ width: '50vw' }} onHide={() => {setNewPassword("");setVisiblePassword(false);if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});}}>
            <p className="m-0">
                {newPassword}
            </p>
        </Dialog>
        </div>
    );
}