import React,{useContext,useEffect, useState, useRef,useReducer} from 'react';
import { DatabaseContext } from '../../redux/DatabaseStore';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from "primereact/checkbox";
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { getRequest, postRequest } from '../../api/ApiController';
import { SaveIcon, AddIcon, CopyIcon, PrintIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, SaveAndCloseIcon} from "../svg/svgIcons";
import {useReactToPrint} from "react-to-print";
import { Dialog } from 'primereact/dialog';
import { InfinitySpin } from  'react-loader-spinner';

export default function RoleAdd({role,type}) {

    const [state,dispatch] = useContext(DatabaseContext);
    const permissions = [
        {name: 'Povolenia Priority',view:{name:'permissions',value:'access priorities'},add:{name:'permissions',value:'add priorities'},edit:{name:'permissions',value:'edit priorities'},delete:{name:'permissions',value:'delete priorities'}},
        {name: 'Povolenia OTK - Sekcia 1',view:{name:'permissions',value:'access otk-section1'},add:{name:'permissions',value:'add otk-section1'},edit:{name:'permissions',value:'edit otk-section1'},delete:{name:'permissions',value:'delete otk-section1'}},
        {name: 'Povolenia OTK - Sekcia 2',view:{name:'permissions',value:'access otk-section2'},add:{name:'permissions',value:'add otk-section2'},edit:{name:'permissions',value:'edit otk-section2'},delete:{name:'permissions',value:'delete otk-section2'}},
        {name: 'Povolenia OTK - Sekcia 3',view:{name:'permissions',value:'access otk-section3'},add:{name:'permissions',value:'add otk-section3'},edit:{name:'permissions',value:'edit otk-section3'},delete:{name:'permissions',value:'delete otk-section3'}},
        {name: 'Povolenia Lakovňa',view:{name:'permissions',value:'access painting'},add:{name:'permissions',value:'add painting'},edit:{name:'permissions',value:'edit painting'},delete:{name:'permissions',value:'delete painting'}},
        {name: 'Povolenia Kalibrácia',view:{name:'permissions',value:'access calibration'},add:{name:'permissions',value:'add calibration'},edit:{name:'permissions',value:'edit calibration'},delete:{name:'permissions',value:'delete calibration'}},
        {name: 'Povolenia Školenia',view:{name:'permissions',value:'access training'},add:{name:'permissions',value:'add training'},edit:{name:'permissions',value:'edit training'},delete:{name:'permissions',value:'delete training'}},
        {name: 'Povolenia Údržba',view:{name:'permissions',value:'access maintenance'},add:{name:'permissions',value:'add maintenance'},edit:{name:'permissions',value:'edit maintenance'},delete:{name:'permissions',value:'delete maintenance'}},
        {name: 'Povolenia Nákup',view:{name:'permissions',value:'access purchase-sp'},add:{name:'permissions',value:'add purchase-sp'},edit:{name:'permissions',value:'edit purchase-sp'},delete:{name:'permissions',value:'delete purchase-sp'}},
        {name: 'Povolenia Nastavenia',view:{name:'permissions',value:'access settings'},add:{name:'permissions',value:'add settings'},edit:{name:'permissions',value:'edit settings'},delete:{name:'permissions',value:'delete settings'}},
        {name: 'Povolenia Používatelia',view:{name:'permissions',value:'access users'},add:{name:'permissions',value:'add users'},edit:{name:'permissions',value:'edit users'},delete:{name:'permissions',value:'delete users'}},
    ];
    const [formRole,setFormRole] = useState(role);
    const [roleType, setRoleType] = useState(type);

    const [stepBackIcon,setStepBackIcon] = useState(true);
    const [scrollIcons,setScrollIcons] = useState(true);

    const [visibleLoading,setVisibleLoading] = useState(false);
    const [favoriteVisible,setFavoriteVisible] = useState(false);
    const [favoriteGroupValue,setFavoriteGroupValue] = useState("");
    const [favoriteValue,setFavoriteValue] = useState("");

    const [closeTab,setCloseTab] = useState(false);

    const toast = useRef(null);
    const submitRef = useRef(null);
    const componentRef = useRef(null);
    const handlePrint = useReactToPrint({
        onBeforeGetContent: () => {componentRef.current.style.display = "block";},
        onAfterPrint: () => {componentRef.current.style.display = "none";},
        content: () => componentRef.current,
    });

    useEffect(() => {
        // Update the document title using the browser API
        if(roleType=="edit"){
            setScrollIcons(false);
        }
    },[roleType]);

    const validate = values => {
        const errors = {};
        /*if (!values.type) {
            errors.type = '*Povinné';
        }*/
        return errors;
    };

    /* form for adding error record */
    const formik = useFormik({
        initialValues: formRole==null?{
            id:'',permissions:[]
        }:{
            id: formRole.id,name: formRole.name,
            permissions: formRole.permissions.map((permission) => permission.name)  
        },
        enableReinitialize:true,
        validate,
        onSubmit: values => {
            let formData = new FormData();
            for (let value in values) {
                formData.append(value, values[value]);
            }
            if(roleType=="add"){
                postRequest('/api/roles', formData,true)
                .then((response) => {
                    console.log(response);
                    getRequest("/api/roles").then((response) => {
                        dispatch({type:'load_roles',newRoles:response.data});
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        if(response.data.length-1>=0)formik.setValues(response.data[response.data.length-1]);
                        setRoleType("edit");
                        toast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                    });
                }).catch((reason) => {
                    if(!navigator.online){
                        alert("Ste offline, dáta budu odoslané po znovupripojení!");
                    }
                    else{
                        alert("Daný úkon sa nepodaril!");
                    }
                })
            }
            else{
                postRequest(`/api/roles/update/${formik.values.id}`, formData,true)
                .then((response) => {
                    getRequest("/api/roles").then((response) => {
                        dispatch({type:'load_roles',newRoles:response.data});
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        formik.setValues(values);
                        toast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne upravený!" });
                    });
                }).catch((reason) => {
                    if(!navigator.online){
                        alert("Ste offline, dáta budu odoslané po znovupripojení!");
                    }
                    else{
                        alert("Daný úkon sa nepodaril!");
                    }
                })
            }
        },
    });

    function addNewRole(){
        dispatch({type:'create_new_tab',name:'NOVÁ ROLA',page:'RoleAdd',props:{role: null,type:'add'},mask:'glo012'})
    }

    function copyRole(){
        dispatch({type:'create_new_tab',name:'NOVÁ ROLA',page:'RoleAdd',props:{role: formik.values,type:'copy'},mask:'glo012'})
    }

    function getIndex(role_id) {
        return state.roles.findIndex(obj => obj.id === role_id);
    }

    async function scrollToFirst(){
        let role = state.roles[0];
        setFormRole(role);
    }
    
    async function scrollUp(){
        let id = getIndex(formik.values.id);
        if(id-1>=0){
            setFormRole(state.roles[id-1]);
        }
    }
    
    async function scrollDown(){
        let id = getIndex(formik.values.id);
        if(id+1<state.roles.length){
            setFormRole(state.roles[id+1]);
        }
    }
    
    async function scrollToLast(){
        let last = state.roles.length-1;
        let role = state.roles[last];
        setFormRole(role);
    }

    function confirmFavoriteDialog(){
        if(favoriteGroupValue==""){
          alert("Vyberte skupinu!");
          return;
        }
        else if(favoriteGroupValue=="nova"){
          if(favoriteValue==""){
            alert("Napíšte názov novez skupiny!");
            return;
          }
          else{
            //VYTVORIT SKUPINU AJ FAVORITA
            setFavoriteVisible(false);
            setVisibleLoading(true);
            let formData = new FormData();
            formData.append("name", favoriteValue);
            postRequest(`/api/favorite_groups`, formData,true)
            .then((response) => {
              let formData2 = new FormData();
              formData2.append("name", "Nová Rola");
              formData2.append("page", "RoleAdd");
              formData2.append("favorite_group_id", response.data.id);
              postRequest(`/api/favorites`, formData2,true)
              .then((response) => {
                  getRequest("/api/my_favorite_groups").then((response) => {
                    dispatch({type:'load_favorites',newFavorites:response.data});
                    setVisibleLoading(false);
                });
              }).catch((reason) => {
                  setVisibleLoading(false);
              })
            }).catch((reason) => {
                setVisibleLoading(false);
            })
          }
        }
        else{
          //VYTVORIT FAVORITA a pridat do skupiny
          setFavoriteVisible(false);
          setVisibleLoading(true);
          let formData2 = new FormData();
          formData2.append("name", "Nová Rola");
          formData2.append("page", "RoleAdd");
          formData2.append("favorite_group_id", favoriteGroupValue);
          postRequest(`/api/favorites`, formData2,true)
          .then((response) => {
              getRequest("/api/my_favorite_groups").then((response) => {
                dispatch({type:'load_favorites',newFavorites:response.data});
                setVisibleLoading(false);
            });
          }).catch((reason) => {
              setVisibleLoading(false);
          })
        }
    }

    const accessTemplate = (rowData, options) => {
        return <Checkbox name={rowData.view.name} value={rowData.view.value} checked={formik.values[rowData.view.name].includes(rowData.view.value)}
        onChange={(e) => {
            let view = [...formik.values[rowData.view.name]];
            if (e.checked)
                view.push(e.value);
            else
                view.splice(view.indexOf(e.value), 1);
            formik.setFieldValue(rowData.view.name, view);
        }} className="p-button-sm p-button-text"></Checkbox>;
    };

    const addTemplate = (rowData, options) => {
        return <Checkbox name={rowData.add.name} value={rowData.add.value} checked={formik.values[rowData.add.name].includes(rowData.add.value)}
        onClick={(e) => {
            let add = [...formik.values[rowData.add.name]];
            if (e.checked){
                add.push(e.value);
                if(!add.includes(rowData.view.value))add.push(rowData.view.value);
            }
            else
                add.splice(add.indexOf(e.value), 1);
            formik.setFieldValue(rowData.add.name, add);
        }} className="p-button-sm p-button-text"></Checkbox>;
    };

    const editTemplate = (rowData, options) => {
        return <Checkbox name={rowData.edit.name} value={rowData.edit.value} checked={formik.values[rowData.edit.name].includes(rowData.edit.value)}
        onClick={(e) => {
            let edit = [...formik.values[rowData.edit.name]];
            if (e.checked){
                edit.push(e.value);
                if(!edit.includes(rowData.add.value))edit.push(rowData.add.value);
                if(!edit.includes(rowData.view.value))edit.push(rowData.view.value);
            }
            else
                edit.splice(edit.indexOf(e.value), 1);
            formik.setFieldValue(rowData.edit.name, edit);
        }} className="p-button-sm p-button-text"></Checkbox>;
    };

    const deleteTemplate = (rowData, options) => {
        return <Checkbox name={rowData.delete.name} value={rowData.delete.value} checked={formik.values[rowData.delete.name].includes(rowData.delete.value)}
        onClick={(e) => {
            let delet = [...formik.values[rowData.delete.name]];
            if (e.checked){
                delet.push(e.value);
                if(!delet.includes(rowData.edit.value))delet.push(rowData.edit.value);
                if(!delet.includes(rowData.add.value))delet.push(rowData.add.value);
                if(!delet.includes(rowData.view.value))delet.push(rowData.view.value);
            }
            else
                delet.splice(delet.indexOf(e.value), 1);
            formik.setFieldValue(rowData.delete.name, delet);
        }} className="p-button-sm p-button-text"></Checkbox>;
    };

    return (
        <>
        <div>
        <div className='w-full h-10 flex flex-row bg-[#333]'>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button type='submit' onClick={() => {setCloseTab(true);submitRef.current.click();}}><SaveAndCloseIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button type='submit' onClick={() => {submitRef.current.click();}}><SaveIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button onClick={() => addNewRole()}><AddIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button onClick={() => copyRole()}><CopyIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="opacity-25"><PrintIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollToFirst()} disabled={scrollIcons}><BackToFirstIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollUp()} disabled={scrollIcons}><BackIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollDown()} disabled={scrollIcons}><ForwardIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollToLast()} disabled={scrollIcons}><ForwardToLastIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button onClick={() => setFavoriteVisible(true)}><FavoriteIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button><PriceIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
        </div>
        <form onSubmit={formik.handleSubmit}>
        <div className="relative flex flex-col justify-left overflow-x min-h-[calc(100vh-177px)]">
          <div className="gap-1 mb-2">
            <div className="w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md">
                <h1 className="text-xl font-semibold text-left text-zinc-900">
                   POUŽÍVATEĽSKÁ ROLA
                </h1>
                {roleType=="edit" && <label className="block text-md font-semibold text-gray-800">Por.č.: {formik.values.id}</label>}
                    <div className="md:w-auto mb-2">
                      <div className="mb-4">
                        <label
                            htmlFor="artikel"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Meno
                        </label>
                        <input
                            name='name'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.name}
                            className="block w-auto  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                    <div className='bg-gray-100'>
                    <DataTable value={permissions} tableStyle={{ minWidth: '50rem'}} size="small">
                        <Column field="name" header="" headerStyle={{backgroundColor: 'white'}}></Column>
                        <Column field="view" header="Prezerať" body={accessTemplate} style={{textAlign:'center' }} headerStyle={{backgroundColor: 'white',paddingLeft:'4%'}}></Column>
                        <Column field="add" header="Vkladať" body={addTemplate} style={{textAlign:'center' }} headerStyle={{backgroundColor: 'white',paddingLeft:'4%'}}></Column>
                        <Column field="edit" header="Upravovať" body={editTemplate} style={{textAlign:'center' }} headerStyle={{backgroundColor: 'white', paddingLeft:'5%'}}></Column>
                        <Column field="delete" header="Mazať" body={deleteTemplate} style={{textAlign:'center' }} headerStyle={{backgroundColor: 'white',paddingLeft:'4%'}}></Column>
                    </DataTable>
                
        </div>
        </div>
        </div>
        <button type="submit" ref={submitRef} className='hidden'></button>
        </div>
        </div>
        </form>
        <Dialog header="Pridať do skupiny:" visible={favoriteVisible} style={{ width: '40vw' }} onHide={() => setFavoriteVisible(false)}>
        <div className="flex flex-columns">
          <div className="flex-row">
          <select 
          value={favoriteGroupValue} onChange={e => setFavoriteGroupValue(e.target.value)}
          className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
            <option  value="">Vyberte možnosť</option> 
            {state.favorites.map((item) => (
              <option key={item.id} value={item.id}>{item.name}</option>
            ))}  
            <option  value="nova">Vytvoriť novú</option>               
          </select>
          {favoriteGroupValue=="nova" && <input
                    value={favoriteValue} onChange={e => setFavoriteValue(e.target.value)}
                    type="text"
                    placeholder="Názov skupiny..."
                    className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                />}
                </div>
          <button onClick={() => confirmFavoriteDialog()} className='w-22 h-10 ml-8 mt-2 px-4 py-2 text-center tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'>ULOŽIŤ</button>
        </div>
        </Dialog>
        </div>
        </>
    )
}