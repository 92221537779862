import React, { useContext,useState, useEffect, useRef  } from "react";
import { ReactTabulator,reactFormatter } from 'react-tabulator';
import { DatabaseContext } from "../../redux/DatabaseStore";
import '../../assets/css/style.css';
import '../../assets/css/iconsmenu.css'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { MdSearch } from "react-icons/md";
import { MdSearchOff } from "react-icons/md";
import { AddIcon, EditIcon, CopyIcon, DeleteIcon, StepBackIcon, PrintIcon, SearchIcon, ExportIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon,EditRowIcon, CalendarIcon} from "../svg/svgIcons";
import { deleteRequest, getRequest,postRequest } from '../../api/ApiController';
import {useReactToPrint} from "react-to-print";
import EightDreport from "../print/EightDreport";
import { InfinitySpin } from  'react-loader-spinner';
import { DateTime } from "luxon";
import AuthContext from "../../components/AuthContext";
import moment from "moment";
import UtilContext from "../../components/UtilContext";
import { PanelMenu } from 'primereact/panelmenu';
import { Checkbox } from 'primereact/checkbox';
import IntersectionObserverWrapper from '../../components/toolbar/IntersectionObserverWrap';
import { OverflowMenuProvider } from '../../components/toolbar/OverflowMenu';


export default function ZoznamExt({preview=null,timePeriod=null,date=null}) {

  const [state,dispatch] = useContext(DatabaseContext);
  const {user} = useContext(AuthContext);
  const {openFavoriteDialog,toast,setFastView,setUserMaskFilter} = useContext(UtilContext);

  const [stepBackIcon,setStepBackIcon] = useState(true);
  const [insertIcon,setInsertIcon] = useState(!user.permissions.includes("add otk-section1"));
  const [editIcon,setEditIcon] = useState(true);
  const [deleteIcon,setDeleteIcon] = useState(true);
  const [favoriteIcon,setFavoriteIcon] = useState(false);
  const [copyIcon,setCopyIcon] = useState(true);
  const [searchIconColor,setSearchIconColor] = useState("");

  const [visibleSearch,setVisibleSearch] = useState(false);
  const [visibleLoading,setVisibleLoading] = useState(false);
  const [searchValue,setSearchValue] = useState('');
  const [scrollValue,setScrollValue] = useState(1);
  const [printVisible,setPrintVisible] = useState(false);
  const [printValue,setPrintValue] = useState("Zoznam");
  const [visibleCalendar,setVisibleCalendar] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [markUnmark, setMarkUnmarked] = useState(true);

  const [columnsVisible, setColumnsVisible] = useState({specific_id: true,article_number: true,article_name:true,order_number:true,'customer.name':true,
  offender_id:true,repair_costs:true,total_amount:true,defective_pieces:true,'group.name':true,ack_error:true,claim_counterparty_number:true,
  'error_type.name':true,'contractor.name':true,contractor_note:true, 'record_processor.name':true,problem_description:true,
  main_reason:true,immediate_measure:true,long_term_measure:true,'prevention.name':true,'auditor.name':true,'auditor_note':true,state:true,created_at:true
  });
  const [maskVisible, setMaskVisible] = useState(false);

  const [notificationVisible, setNotificationVisible] = useState(false);
  const [chosenNotifications, setChosenNotifications] = useState([]);

  const [editErrorRecord, setEditErrorRecord] = useState([]);

  const ref = useRef(null);
  const elementRef = useRef(null);
  const componentRef = useRef(null);
  const overlayRef = useRef(null);
  const ownToast = useRef(null);
  const handlePrint = useReactToPrint({
    onBeforeGetContent: () => {componentRef.current.style.display = "block";},
    onAfterPrint: () => {componentRef.current.style.display = "none";},
    content: () => componentRef.current,
  });

  
  function matchAny(data, filterParams) {
    var match = false;
    const regex = RegExp(filterParams.value, 'i');

    for (var key in data) {
        if (regex.test(data[key]) === true) {
            match = true;
        }
    }
    return match;
  }

  function dateRange(data, filterParams) {
    console.log(data.created_at,filterParams);
    return moment(filterParams.fromDate).isSameOrBefore(data.created_at.substring(0,10)) && moment(filterParams.toDate).isSameOrAfter(data.created_at.substring(0,10));
  }

 
  function searchTable(){
    ref.current.setFilter(matchAny, { value: searchValue});
    setSearchIconColor("active");
    if (searchValue === " ") {
      ref.current.clearFilter()
    }
    setVisibleSearch(false);
  }

  function searchByDate(){
    ref.current.setFilter(dateRange, {fromDate:fromDate,toDate:toDate });
    setVisibleCalendar(false);
  }

  const accept = () => {
    let rows_to_delete = [];
    rows_to_delete = ref.current.getSelectedData();
    rows_to_delete.forEach(element => {
      deleteRequest(`/api/error_records/${element.id}`)
      .then((response) => {
        if(toast.current)toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne vymazaný!" });
        else ownToast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne vymazaný!" });  
        getRequest("/api/error_records").then((response) => {
          dispatch({type:'load_error_records',newErrorRecords:response.data});
        });             
      }).catch((reason) => {
          alert(reason);
          if(toast.current)toast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Záznam sa nepodarilo vymazať!', life: 3000 });
          else ownToast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Záznam sa nepodarilo vymazať!', life: 3000 }); 
      })
    });  
        //toast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne vymazaný!" });
  }

  const reject = () => {
    if(toast.current)toast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavka bola zrušená!', life: 3000 });
    else ownToast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavka bola zrušená!', life: 3000 });
  }

  const confirm = () => {
      confirmDialog({
        message: 'Prajete si vymazať tento záznam/y?',
        header: 'Potvrdenie vymazania',
        icon: 'pi pi-info-circle',
        rejectClassName: 'p-button-danger',
        acceptLabel: 'Nie',
        rejectLabel: 'Áno',
        reject: accept,
        accept: reject
    });
  };

  const rowClick = (e, row) => {
    console.log(e, row);
 };

 const onSelectRow = (data, rows) => {
  setEditErrorRecord(data);
  setEditIcon(true);
  setCopyIcon(true);
  setDeleteIcon(true);
  if(data.length !== 0){
    if(user.permissions.includes("add otk-section1") || data[0].contractor_id==user.employee.id)setCopyIcon(false);
    if(user.permissions.includes("add otk-section2") || data[0].contractor_id==user.employee.id)setEditIcon(false);
    if(user.permissions.includes("add otk-section3"))setDeleteIcon(false);
  }
};

function OffenderFormatter(props) {
  const rowData = props.cell._cell.row.data;
  if(rowData.offender==null)return "";
  if(rowData.offender.type=="customer"){
    return state.customers
            .filter(customer => customer.offender_id === rowData.offender_id)[0]?.name;
  }
  else if(rowData.offender.type=="supplier"){
    return state.suppliers
            .filter(supplier => supplier.offender_id === rowData.offender_id)[0]?.name;
  }
  else{
    return state.employees
            .filter(employee => employee.offender_id === rowData.offender_id)[0]?.name;
  }
}

function onEditRow(error_record){
  dispatch({type:'create_new_tab',name:'UPRAVIŤ EXT. CHYBA',page:'ExternaChyba',props:{error_record: error_record,er_type:'edit'},mask:'otk005'})
};

function EditRowFormatter(props) {
  const rowData = props.cell.getRow().getData();
  return <div className='flex justify-center items-start w-full h-full'><button type='button' onClick={() => onEditRow(rowData)}><EditRowIcon/></button></div>;
}

{/*{title: 'Custom', field: 'custom', formatter: reactFormatter(<CustomFormatter />)},*/}
const [draggingItem,setDraggingItem] = useState(null); 
const [columns,setColumns] = useState([
  { formatter:"rowSelection",field:'id', titleFormatter:"rowSelection", hozAlign:"center",width: 20,headerSort:false},
  { hozAlign:"center",width: 20,headerSort:false,formatter: reactFormatter(<EditRowFormatter/>),resizable:false},
  { title: 'Poradové číslo', field: 'specific_id',headerFilter:"input",width: 200},
  { title: 'Artikel', field: 'article_number',headerFilter:"input",width:200 },
  { title: 'Názov artikla', field: 'article_name',headerFilter:"input",width:200 },
  { title: 'Zákazka', field: 'order_number',headerFilter:"input",width:200 },
  { title: 'Zákazník', field: 'customer.name',headerFilter:"input",width:200},
  { title: 'Vinník', field: 'offender_id',headerFilter:"input",width:200,formatter: reactFormatter(<OffenderFormatter />)},
  { title: 'Náklady', field: 'repair_costs',headerFilter:"input",width:200},
  { title: 'Množstvo', field: 'total_amount',headerFilter:"input",width:200},
  { title: 'Počet chybných', field: 'defective_pieces',headerFilter:"input",width:200},
  { title: 'Skupina', field: 'group.name',headerFilter:"input",width:200},
  { title: 'Chyba', field: 'ack_error',headerFilter:"input",width:200},
  { title: 'Číslo rek. protistrany', field: 'claim_counterparty_number',headerFilter:"input",width:250},
  { title: 'Druh chyby', field: 'error_type.name',headerFilter:"input",width:200},
  { title: 'Zadávateľ', field: 'contractor.name',headerFilter:"input",width:200},
  { title: 'Pozn. zadávateľa', field: 'contractor_note',headerFilter:"input",width:200},
  { title: 'Spracovateľ', field: 'record_processor.name',headerFilter:"input",width:200},
  { title: 'Popis problému', field: 'problem_description',headerFilter:"input",width:200},
  { title: 'Hlavná príčina', field: 'main_reason',headerFilter:"input",width:200},
  { title: 'Okamžité opatrenie', field: 'immediate_measure',headerFilter:"input",width:200},
  { title: 'Dlhodobé opatrenie', field: 'long_term_measure',headerFilter:"input",width:200},
  { title: 'Zabránenie skrz', field: 'prevention.name',headerFilter:"input",width:200},
  { title: 'Kontrolór', field: 'auditor.name',headerFilter:"input",width:200},
  { title: 'Pozn. kontrolóra', field: 'auditor_note',headerFilter:"input",width:200},
  { title: 'Stav', field: 'state',headerFilter:"input",width:200},
  { title: 'Dátum', field: 'created_at',headerFilter:"input",width:200,formatter: function (cell, formatterParams) {
      const { outputFormat = "dd.MM.yyyy HH:mm" } = formatterParams;
      let value = cell.getValue();
      return DateTime.fromISO(value).toFormat(outputFormat);
    } 
  },
]);

  async function scrollToFirst(){
    let res = state.error_records_ext[0].id;
    ref.current.scrollToRow(res, "top", true);
    setScrollValue(1);
  }

  async function scrollUp(){
    let visible_rows = Math.floor((elementRef.current.clientHeight-68)/30-1);
    if((scrollValue-visible_rows+1) >= 0){
      let res = state.error_records_ext[scrollValue-visible_rows+1].id;
      ref.current.scrollToRow(res, "top", true);
      setScrollValue(scrollValue-visible_rows);
    }
    else if(scrollValue > 0 && scrollValue <= visible_rows){
      let res = state.error_records_ext[0].id;
      ref.current.scrollToRow(res, "top", true);
      setScrollValue(1);
    }
  }

  async function scrollDown(){
    let visible_rows = Math.floor((elementRef.current.clientHeight-68)/30-1);
    let rows = ref.current.getRows(true).length;
    if(scrollValue===1){
      let res = state.error_records_ext[scrollValue+visible_rows-1].id;
      ref.current.scrollToRow(res, "top", true);
      setScrollValue(scrollValue+visible_rows);
    }
    else if((scrollValue+visible_rows) <= rows){
      let res = state.error_records_ext[scrollValue+visible_rows-1].id;
      let rows = ref.current.getRows(true).length;
      setScrollValue(scrollValue+visible_rows);
      if(res > rows-visible_rows){
        res = state.error_records_ext[rows-visible_rows].id;
        setScrollValue(rows-visible_rows);
      }
      ref.current.scrollToRow(res, "top", true);
    }
  }

  async function scrollToLast(){
    let visible_rows = Math.floor((elementRef.current.clientHeight-68)/30-1);
    let rows = ref.current.getRows(true).length;
    let res = state.error_records_ext[rows-visible_rows].id;
    ref.current.scrollToRow(res, "top", true);
    setScrollValue(rows-visible_rows);
  }

  function addNewExtError(){
    dispatch({type:'create_new_tab',name:'NOVÁ EXT. CHYBA',page:'ExternaChyba',props:{error_record: null,er_type:'add'},mask:'otk005'})
  }
  function copyExtError(){
    dispatch({type:'create_new_tab',name:'NOVÁ EXT. CHYBA',page:'ExternaChyba',props:{error_record: ref.current.getSelectedData()[0],er_type:'add'},mask:'otk005'})
  }
  function editExtError(){
    dispatch({type:'create_new_tab',name:'EDIT EXT. CHYBA',page:'ExternaChyba',props:{error_record: ref.current.getSelectedData()[0],er_type:'edit'},mask:'otk005'})
  }

  function confirmPrintDialog(){
    if(printValue=="Zoznam"){
      ref.current.print(false, true)
    }
    else{
      handlePrint();
    }
  }

  function handleDragStart(e, item){ 
    setDraggingItem(item);
    e.dataTransfer.setData('text/plain', ''); 
  }; 

  function handleDragEnd(){ 
    setDraggingItem(null);
  }; 

  function handleDragOver(e){ 
      e.preventDefault(); 
  }; 

  function handleDrop(e, targetItem){ 
      if (!draggingItem) return; 

      const currentIndex = columns.indexOf(draggingItem); 
      const targetIndex = columns.indexOf(targetItem); 

      if (currentIndex !== -1 && targetIndex !== -1) { 
        columns.splice(currentIndex, 1); 
        columns.splice(targetIndex, 0, draggingItem); 
         
      } 
      ref.current.setColumns(columns);
  }; 

function setMask(item,value){
  const columnVisible = {...columnsVisible};
 columnVisible[item] = value;
  for(const val in columnVisible) {
    if(columnVisible[val]==false){
      ref.current.hideColumn(`${val}`);
    }
    else{
      ref.current.showColumn(`${val}`);
    }
  }
  setColumnsVisible(columnVisible);
}

const itemRenderer = (item, options) => (
  <a className="flex align-items-center px-3 py-2 cursor-pointer items-center bg-[#333] text-white w-[180px]" onClick={options.onClick}>
      <span className={`${item.icon} text-primary`} />
      <span className={`mx-2 ${item.items && 'font-semibold'}`}>{item.label}</span>
      {item.shortcut && <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{item.shortcut}</span>}
  </a>
);

const [expandedKeys, setExpandedKeys] = useState({});

const handleClickOutside = (event) => {
  if (overlayRef.current && !overlayRef.current.contains(event.target)) {
      setExpandedKeys({});
  }
};

useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
        document.removeEventListener('click', handleClickOutside, true);
    };
}, []);

  const items = [
  {
    key: '0',
    label: 'Akcie',
    icon: 'pi pi-sliders-v',
    template: itemRenderer,
    items: [
          {
            key: '0_1',
            label: 'Nastaviť filter',
            icon: 'pi pi-filter',
            command: () => {setUserMaskFilter("ZoznamExt","set_filters",null,ref.current.getHeaderFilters(),null);},
            template: itemRenderer,
        },
          {
            key: '0_2',
            label: 'Zrušiť filter',
            icon: 'pi pi-filter-slash',
            command: () => {setUserMaskFilter("ZoznamExt","set_filters",null,[],null);ref.current.clearHeaderFilter();},
            template: itemRenderer,
        },
        {
            key: '0_3',
            label: 'Nastaviť masku',
            icon: 'pi pi-server',
            command: () => {setMaskVisible(true);OverflowMenuProvider.setVisible(null);setExpandedKeys({});},
            template: itemRenderer,
        },
        {
          key: '0_4',
          label: 'Notifikácie',
          icon: 'pi pi-envelope',
          command: () => {showChosenNotifications();OverflowMenuProvider.setVisible(null);},
          template: itemRenderer,
      },
    ]
  }
];

function showChosenNotifications(){
  let db_chosen_notifications = state.chosen_notifications?.filter((item => item.type == "ExternaChyba"));
  if(db_chosen_notifications?.length>0){
    setChosenNotifications(JSON.parse(db_chosen_notifications[0].data));
  }
  else{
    setChosenNotifications([]);
  }
  setNotificationVisible(true);
}

function saveChosenNotifications(){
    let formData = new FormData();
    formData.append("type","ExternaChyba");
    formData.append("data", JSON.stringify(chosenNotifications));
    postRequest('/api/chosen_notifications', formData,true)
    .then((response) => {
      if(response.status == 201){
        dispatch({type:'load_chosen_notifications',newChosenNotifications:response.data});
        if(toast.current)toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Stav aktualizovaný!" });
        else ownToast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Stav aktualizovaný!" });  
      }else{
        if(toast.current)toast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavku sa nepodarilo vykonať!', life: 3000 });
        else ownToast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavku sa nepodarilo vykonať!', life: 3000 });  
      }
    }).catch((reason) => {
          console.log(reason);
          alert("Daný úkon sa nepodaril!");
    });
    setNotificationVisible(false);
}
  function previewFilter(data, filterParams){
    //data - the data for the row being filtered
    //filterParams - params object passed to the filter
    if(filterParams.time_period=="Za deň"){
      return new Date(data.created_at).toDateString() == new Date(filterParams.date).toDateString();
      //return new Date(data.created_at) > new Date(new Date().setDate(new Date().getDate() - 365));
    }
    else if(filterParams.time_period=="Za mesiac"){
      return new Date(data.created_at).getFullYear() == new Date(filterParams.date).getFullYear() && new Date(data.created_at).getMonth() == new Date(filterParams.date).getMonth();
    }
    else if(filterParams.time_period=="Za rok"){
      return new Date(data.created_at).getYear() == new Date(filterParams.date).getYear();
    }
    else if(filterParams.time_period=="Vlastné"){
      var end_date = new Date(filterParams.date[1]);
      end_date.setDate(end_date.getDate() + 1);
      return new Date(data.created_at).valueOf() >= new Date(filterParams.date[0]).valueOf() && new Date(data.created_at).valueOf() <= new Date(end_date).valueOf();
    }
    else if(filterParams.time_period=="Všetko"){
      return true;
    }
    return false;
  }

  function changeMarkings(value){
    if(value){
      const columnVisible = {...columnsVisible};
      for(let val in columnVisible) {
        if(columnVisible[val]!=="id" || columnVisible[val]!=="editor"){
          columnVisible[val]=true;
          ref.current.showColumn(`${val}`);
        }
      };
      setColumnsVisible(columnVisible);
    }
    else{
      const columnVisible = {...columnsVisible};
      for(let val in columnVisible) {
        if(columnVisible[val]!=="id" || columnVisible[val]!=="editor"){
          columnVisible[val]=false;
          ref.current.hideColumn(`${val}`);
        }
      };
      setColumnsVisible(columnVisible);
    }
  }


    return (
        <>
        <Toast ref={ownToast}/>
        <div className='w-full h-10 flex flex-row bg-[#333]'>
        <IntersectionObserverWrapper>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="add"><button className="disabled:opacity-25" disabled={insertIcon} onClick={() => addNewExtError()}><AddIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="edit"><button className="disabled:opacity-25" disabled={editIcon} onClick={() => editExtError()}><EditIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="copy"><button className="disabled:opacity-25" disabled={copyIcon} onClick={() => copyExtError()}><CopyIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="delete"><button className="disabled:opacity-25" onClick={confirm} disabled={deleteIcon}><DeleteIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="print"><button onClick={() => setPrintVisible(true)}><PrintIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="search"><button onClick={searchIconColor!="active"? () => {setVisibleSearch(true);}:() => {setSearchIconColor("");setSearchValue("");ref.current.clearFilter();}}>{searchIconColor == "" ?  <MdSearch fill='#fff' strokeWidth="0" className='w-[28px] h-[28px]' aria-hidden="true" title='Hľadať'/> : <MdSearchOff fill='#fff' strokeWidth="0" className='w-[28px] h-[28px]' aria-hidden="true" title='Zrušiť hľadanie'/>}</button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="export"><button onClick={() => ref.current.download("xlsx", "data.xlsx", {sheetName:"MyData"})}><ExportIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center align-center ' data-targetid="calendar"><button onClick={() => setVisibleCalendar(true)}><CalendarIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '  data-targetid="vert"><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="first"><button onClick={() => scrollToFirst()}><BackToFirstIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="back"><button onClick={() => scrollUp()}><BackIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="forward"><button onClick={() => scrollDown()}><ForwardIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="last"><button onClick={() => scrollToLast()}><ForwardToLastIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '  data-targetid="vert"><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="favorite"><button className="disabled:opacity-25" disabled={favoriteIcon} onClick={() => openFavoriteDialog("Externá chyba","ZoznamExt")}><FavoriteIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="fast"><button onClick={() => setFastView("ZoznamExt",columnsVisible,columns)}><PriceIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '  data-targetid="vert"><VerticalLineIcon/></div>
          <div className="card flex justify-content-center z-[100] " data-targetid="actions" ref={overlayRef}>
            <PanelMenu model={items} expandedKeys={expandedKeys} onExpandedKeysChange={setExpandedKeys} className="w-full md:w-20rem"/>
          </div>
    </IntersectionObserverWrapper>
      </div>
<div className='px-1 bg-white rounded-md shadow-md'>
<div className="flex flex-col overflow-x-auto no-scrollbar min-h-[calc(100vh-177px)]">
  <div className="sm:-mx-6 lg:-mx-8 overflow-hidden">
    <div className="inline-block w-full py-2 sm:px-6 lg:px-8" >
      <div ref={elementRef}>
        <ReactTabulator
        data={state.error_records_ext} 
        className='h-[calc(100vh_-_193px)]' //176px
        onRef={(r) => (ref.current = r.current)}
        columns={columns}
        events={{
          rowClick: rowClick,
          rowSelectionChanged: onSelectRow,
          tableBuilt: () => {
            if(preview!=null){
              ref.current.setFilter(previewFilter, {time_period:timePeriod,date: date});
              if(preview=="open"){
                ref.current.addFilter("state", "=", "Otvorená");
              }
              else if(preview=="closed"){
                ref.current.addFilter("state", "=", "Zatvorená");
              }
            }
            let mask = state.user_mask_filters.filter(mask => mask.page=="ZoznamExt");
            if(mask.length>0){
              let attributes = [];
              try {
                attributes = JSON.parse(mask[0].attributes)
              } catch (e) {
                attributes = []
              }
              if(attributes?.length>1)ref.current.setColumnLayout(attributes);
              let filters = [];
              try {
                filters = JSON.parse(mask[0].filters);
              } catch (e) {
                filters = []
              }
              filters.forEach(filter => {
                ref.current.setHeaderFilterValue(filter.field,filter.value);
              });
              let json_columns;
              try {
                json_columns = JSON.parse(mask[0].columns);
                setColumnsVisible(json_columns);
              } catch (e) {
                json_columns = []
              }
              for(let column in json_columns){
                if(json_columns[column]==false)ref.current.hideColumn(column);
                else ref.current.showColumn(column);
              }
            }
          },
          columnMoved:() => {
            setUserMaskFilter("ZoznamExt","column_move",null,null,ref.current.getColumnLayout());
          }
        }}
        options={{ 
        renderHorizontal:"virtual",movableColumns: true,
        printAsHtml:true,printHeader:"<h1>Zoznam externých chýb<h1>",printRowRange:"active",}}/> 
      </div> 
    </div> 
  </div> 
</div>
            <Dialog header="Hľadaj v zozname:" visible={visibleSearch} style={{ width: '40vw' }} onHide={() => setVisibleSearch(false)}>
              <div className="flex flex-columns">
                
                <input
                    value={searchValue} onChange={e => setSearchValue(e.target.value)}
                    type="text"
                    className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                />
                <button onClick={() => searchTable()} className='w-16 ml-8 px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'><SearchIcon/></button>
              </div>
            </Dialog>
            <Dialog header="Hľadaj v zozname podľa dátumu:" visible={visibleCalendar} style={{ width: '40vw' }} onHide={() => setVisibleCalendar(false)}>
              <div className="flex flex-columns justify-between">
                <label>
                  Od:
                <input
                    value={fromDate} onChange={e => setFromDate(e.target.value)}
                    type="date"
                    className="block w-full px-1 py-1 mr-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                /> 
                </label>
                <label>
                  Do:
                <input
                    value={toDate} onChange={e => setToDate(e.target.value)}
                    type="date"
                    className="block w-full px-1 py-1 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                />
                </label>
                <button onClick={() => searchByDate()} className='w-16 ml-8 px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'><SearchIcon/></button>
              </div>
            </Dialog>
            <Dialog header="Tlačiť ako" visible={printVisible} style={{ width: '40vw' }} onHide={() => setPrintVisible(false)}>
        <div className="flex flex-columns">
          <select 
          value={printValue} onChange={e => setPrintValue(e.target.value)}
          className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
            <option value="8D">8D report</option>
            <option value="Zoznam">Zoznam</option>                      
          </select>
          <button onClick={() => confirmPrintDialog()} className='w-20 ml-8 px-4 py-2 text-center tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'>TLAČ</button>
        </div>
      </Dialog>
      <Dialog header="Nastaviť masku" visible={maskVisible} style={{ width: '70vw' }} onHide={() => setMaskVisible(false)}>
        <div className="flex flex-col">
        <button type="button" onClick={() => {changeMarkings(!markUnmark);setMarkUnmarked(!markUnmark);}} className='m-1 px-4 py-2 text-center tracking-wide text-white transition-colors duration-200 transform bg-zinc-700 rounded-md hover:bg-zinc-500'>{markUnmark ? "ODZNAČ VŠETKO" : "OZNAČ VŠETKO"}</button>
        {columns.map(function(item, index){ 
          if(index<2) return null;
                    return <div 
                        key={index} 
                        className="flex flex-row border border-black rounded-md p-2 m-1"
                        draggable="true"
                        onDragStart={(e) =>  
                            handleDragStart(e, item)} 
                        onDragEnd={() => handleDragEnd()} 
                        onDragOver={(e) => handleDragOver(e)} 
                        onDrop={(e) => handleDrop(e, item)} 
                    > 

                         <Checkbox onChange={(e) => setMask(item.field,e.checked)} checked={columnsVisible[item.field]}></Checkbox><span className="ml-2">{item.title}</span>
                          
                        {/* Use the React icon component */} 
                    </div> 
                })} 
                <button onClick={() => {setUserMaskFilter("ZoznamExt","set_columns",columnsVisible,null,null);setMaskVisible(false);}} className='m-1 px-4 py-2 text-center tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'>ULOŽIŤ</button>

        </div>
      </Dialog>
      {/* button to trigger printing of target component */}

        {/* component to be printed */}
        <div ref={componentRef} style={{display:'none'}}>
          {editErrorRecord.map((error_record) => (
                             <EightDreport key={error_record.id} error_record={error_record}/>
          ))}
        </div>
        <Dialog header="Nastaviť notifikácie" visible={notificationVisible} style={{ width: '70vw' }} onHide={() => setNotificationVisible(false)}>
            <div className="flex flex-col">
                         <div  
                            className="flex flex-row border border-black rounded-md p-2 m-1" 
                        > 
                            <Checkbox name='Nová' value='Nová' checked={chosenNotifications.includes('Nová')}
                            onClick={(e) => {
                                let edit = [...chosenNotifications];
                                console.log(e,edit);
                                if (e.checked){
                                    edit.push(e.value);
                                }
                                else
                                    edit.splice(edit.indexOf(e.value), 1);
                                setChosenNotifications(edit);
                            }}></Checkbox>
                            <label htmlFor='Nová' className="ml-2">Nová chyba</label>
                        </div> 
                        <div  
                            className="flex flex-row border border-black rounded-md p-2 m-1" 
                        > 
                            <Checkbox name='Zmena stavu' value='Zmena stavu' checked={chosenNotifications.includes('Zmena stavu')}
                            onClick={(e) => {
                                let edit = [...chosenNotifications];
                                console.log(e,edit);
                                if (e.checked){
                                    edit.push(e.value);
                                }
                                else
                                    edit.splice(edit.indexOf(e.value), 1);
                                setChosenNotifications(edit);
                            }}></Checkbox>
                            <label htmlFor='Zmena stavu' className="ml-2">Zmena stavu</label>
                        </div>
                    
              <button type='button' onClick={() => saveChosenNotifications()} className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Uložiť</button>
            </div>
          </Dialog>
</div> 
</>
    );
}