import React,{useContext,useEffect, useState, useRef,useReducer} from 'react';
import { DatabaseContext } from '../../redux/DatabaseStore';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { getRequest, postRequest ,deleteRequest} from '../../api/ApiController';
import { SaveIcon, AddIcon, CopyIcon, PrintIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, SaveAndCloseIcon,EditRowIcon,DeleteIcon, EditIcon, PaperClipIcon} from "../svg/svgIcons";
import { Dialog } from 'primereact/dialog';
import { InfinitySpin } from  'react-loader-spinner';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import AuthContext from '../../components/AuthContext';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { ReactTabulator,reactFormatter } from 'react-tabulator';
import { DateTime } from "luxon";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css"
import '../../assets/css/style.css';
import '../../assets/css/iconsmenu.css'
import UtilContext from '../../components/UtilContext';
import { PanelMenu } from 'primereact/panelmenu';
import { Checkbox } from 'primereact/checkbox';
import { OverflowMenuProvider } from '../../components/toolbar/OverflowMenu';
import IntersectionObserverWrapper from '../../components/toolbar/IntersectionObserverWrap';

export default function GaugeCalibrationAdd({gauge,gauge_calibration, type}) {

    const [state,dispatch] = useContext(DatabaseContext);
    const {setDialogText,setVisible,openFavoriteDialog,toast,setFastView,setUserMaskFilter} = useContext(UtilContext);
    const {user} = useContext(AuthContext);

    const [closeTab,setCloseTab] = useState(false);
    const ref = useRef(null);
    const submitRef = useRef(null);
    const overlayRef = useRef(null);
    const ownToast = useRef(null);

    const [markUnmark, setMarkUnmarked] = useState(true);

    const [columnsVisible, setColumnsVisible] = useState({custom_validator_name:true,custom_calibration_date:true,calibration_number_protocol:true,next_calibration_date:true,verification_result:true,'contractor.name':true,description:true,protocol:true,
    });
    const [maskVisible, setMaskVisible] = useState(false);

    const [notificationVisible, setNotificationVisible] = useState(false);
    const [chosenNotifications, setChosenNotifications] = useState([]);

    const [formGaugeCalibration,setFormGaugeCalibration] = useState(gauge_calibration);
    const [formType, setFormType] = useState(type);

    //TODO dorobit contractor id
    const [insertCopyIcon,setInsertCopyIcon] = useState((user.permissions.includes("add calibration"))?false:true);
    const [scrollIcons,setScrollIcons] = useState((user.permissions.includes("edit calibration"))?false:true);
    const [visibleLoading,setVisibleLoading] = useState(false);
    const [visibleCalibration,setVisibleCalibration] = useState(false);
    const [editIcon,setEditIcon] = useState(true);
    const [deleteIcon,setDeleteIcon] = useState(true);
    const [copyIcon,setCopyIcon] = useState(true);

    const [selectedCalibration,setSelectedCalibration] = useState(null);

    const [showGaugeCalibrationAdd, setShowGaugeCalibrationAdd] = useState(false);
    const [showIntervalCalibration, setShowIntervalCalibration] = useState(false);
    const [visibleFiles,setVisibleFiles] = useState(false);
    const [images,setImages] = useState('');
    const [templates,setTemplates] = useState('');
    const [viewedImage,setViewedImage] = useState('');

    const [fileList, setFileList] = useState([]);
    const fileListRef = useRef(null);

    const [updatefileList, setUpdateFileList] = useState([]);
    const updateFileListRef = useRef(null);

    useEffect(() => {
        // Update the document title using the browser API
        if(formGaugeCalibration!=null){
            getTemplates(formGaugeCalibration.id);
        }
    },[formGaugeCalibration]);

    function addNewCalibration(){
        setShowGaugeCalibrationAdd(true);
    }

    function copyGaugeCalibration(){
        formik.setValues(ref.current.getSelectedData()[0]);
        setShowGaugeCalibrationAdd(true);    
    }

    function editNewGaugeCalibration(){
        formikUpdate.setValues(ref.current.getSelectedData()[0]);
        setVisibleCalibration(true);
    }

    function hideEditCalibration(){
        formikUpdate.resetForm();
        setVisibleCalibration(false);
    }

    const onSelectRow = () => {
        setEditIcon(true);
        setCopyIcon(true);
        setDeleteIcon(true);
        if(ref.current.getSelectedData().length === 1){
            if(user.permissions.includes("edit calibration"))setEditIcon(false);
            if(user.permissions.includes("delete calibration"))setDeleteIcon(false);
            if(user.permissions.includes("add calibration"))setCopyIcon(false);
            setSelectedCalibration(ref.current.getSelectedData()[0]);
        }
    }

    function getImages(){
        getRequest(`/api/gauge_calibrations/protocols/${selectedCalibration.id}`).then((response) => {
            console.log(response);
            if(response.data!=="{}")setImages(response.data);
        });
    }

    function getTemplates(id=null){
        getRequest(`/api/gauges/protocols/${id ? id :selectedCalibration.id}`).then((response) => {
            console.log(response);
            if(response.data!=="{}")setTemplates(response.data);
        });
    }


    const validate = values => {
        const errors = {};
        /*if (!values.type) {
            errors.type = '*Povinné';
        }*/
        return errors;
    };

    const formik = useFormik({
        initialValues:{
            validator_id: '',calibration_date: '',calibration_number_protocol:'',verification_result:'OK',contractor_id:'',description:'',},
        enableReinitialize:true,
        validate,
        onSubmit: values => {
            let formData = new FormData();
            if(values.validator_id == "Interne"){
                values.validator_id = null;
            }
            for (let value in values) {
                formData.append(value, values[value]);
            }
            for(let i = 0;i<fileList.length;i++){
                //console.log("IMAGE :"+fileList[i].name);
                formData.append('files[]',fileList[i]);
            }
            formData.append("gauge_id", gauge.id);
            postRequest('/api/gauge_calibrations', formData,true)
            .then((response) => {
                getRequest("/api/gauges").then((response) => {
                    dispatch({type:'load_gauges',newGauges:response.data});
                });
                getRequest("/api/gauge_calibrations").then((response) => {
                    dispatch({type:'load_gauge_calibrations',newGaugeCalibrations:response.data});
                    if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                    formik.resetForm();
                    if(fileList.length){
                        setFileList([]);
                        fileListRef.current.value = null;
                    }
                    let mytoast = toast.current ? toast : ownToast;
                    mytoast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                    setShowGaugeCalibrationAdd(false);
                });
            }).catch((reason) => {
                if(!navigator.online){
                    alert("Ste offline, dáta budu odoslané po znovupripojení!");
                }
                else{
                    alert("Daný úkon sa nepodaril!");
                }
            });
        },
    });

    const formikUpdate = useFormik({
        initialValues: selectedCalibration == null ? {
            validator_id: 'Interne',calibration_date: '',calibration_number_protocol:'',verification_result:'OK',contractor_id:'',description:'',} : selectedCalibration,
        enableReinitialize:true,
        validate,
        onSubmit: values => {
            let formData = new FormData();
            delete values.files;
            if(values.validator_id == "Interne"){
                values.validator_id = "";
            }
            for (let value in values) {
                formData.append(value, values[value]);
            }
            for(let i = 0;i<updatefileList.length;i++){
                //console.log("IMAGE :"+fileList[i].name);
                formData.append('files[]',updatefileList[i]);
            }
            formData.append("gauge_id",  gauge.id);
            postRequest(`/api/gauge_calibrations/update/${formikUpdate.values.id}`, formData,true)
            .then((response) => {
                getRequest("/api/gauge_calibrations").then((response) => {
                    dispatch({type:'load_gauge_calibrations',newGaugeCalibrations:response.data});
                    if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                    setVisibleCalibration(false);
                    formikUpdate.resetForm();
                    if(updatefileList.length){
                        setUpdateFileList([]);
                        updateFileListRef.current.value = null;
                    }
                    let mytoast = toast.current ? toast : ownToast;
                    mytoast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                });
            }).catch((reason) => {
                if(!navigator.online){
                    alert("Ste offline, dáta budu odoslané po znovupripojení!");
                }
                else{
                    alert("Daný úkon sa nepodaril!");
                }
            });
        },
    });

    function getIndex(gauge_calibrations_id) {
        return state.gauges.findIndex(obj => obj.id === gauge_calibrations_id);
    }

    async function scrollToFirst(){
        let my_gauge_calibration = state.gauge_calibrations[0];
        setFormGaugeCalibration(my_gauge_calibration);
    }
    
    async function scrollUp(){
        let id = getIndex(formik.values.id);
        if(id-1>=0){
            setFormGaugeCalibration(state.gauge_calibrations[id-1]);
        }
    }
    
    async function scrollDown(){
        let id = getIndex(formik.values.id);
        if(id+1<state.gauge_calibrations.length){
            setFormGaugeCalibration(state.gauge_calibrations[id+1]);
        }
    }
    
    async function scrollToLast(){
        let last = state.gauge_calibrations.length-1;
        let my_gauge_calibration = state.gauge_calibrations[last];
        setFormGaugeCalibration(my_gauge_calibration);
    }

    const handleFileChange = (event) => {
        setFileList(event.target.files);
    };

    const handleUpdateFileChange = (event) => {
        setUpdateFileList(event.target.files);
    };

    function CalibrationDate(props) {
        const rowData = props.cell.getRow().getData();
        return rowData.calibration_date ? DateTime.fromISO(rowData.calibration_date).toFormat("dd.MM.yyyy") : "Nekalibrované!";
      }

      function NextCalibrationDate(props) {
        const rowData = props.cell._cell.row.data;
        return rowData.next_calibration_date ? DateTime.fromISO(rowData.next_calibration_date).toFormat("dd.MM.yyyy") : "Nekalibrované!";
      }

      function VerificationResult(props) {
        const rowData = props.cell._cell.row.data;
        return rowData.gauge_calibrations[0]?.verification_result ? rowData.gauge_calibrations[0].verification_result : <span style={{color:'darkred',fontWeight:'bold'}}>Nehodnotené!</span>;
      }

      function onEditRow(gauge_calibration){
        formikUpdate.setValues(gauge_calibration);
        setSelectedCalibration(gauge_calibration);
        getRequest(`/api/gauge_calibrations/protocols/${gauge_calibration.id}`).then((response) => {
            console.log(response);
            if(response.data!=="{}")setImages(response.data);
        });
        setVisibleCalibration(true);
      };
      
      function EditRowFormatter(props) {
        const rowData = props.cell.getRow().getData();
        return <div className='flex items-center justify-center w-full h-full'><button type='button' onClick={() => onEditRow(rowData)}><EditRowIcon/></button></div>;
      }

    function editGaugeCalibration(){
      dispatch({type:'create_new_tab',name:'Upraviť kalibráciu meradla',page:'GaugeCalibrationAdd',props:{gauge_calibration: ref.current.getSelectedData()[0],type:'edit'}})
    }

    function Validator(props) {
        const rowData = props.cell.getRow().getData();
        return rowData.validator_id ? rowData.validator.name : <span>Interne</span>;
      }

    function viewFile(file_id){
        getRequest(`/api/gauge_calibrations/protocol/${file_id}`).then((response) => {
            console.log(response);
            setVisibleFiles(true);
            setViewedImage(response.data);
        });
    }
    function Protocol(props) {
        const rowData = props.cell.getRow().getData();
        return rowData.files ? <div className='flex flex-row'>{rowData.files.map((img) => (<a style={{color:'blue',cursor:'pointer',marginBottom:'2px',width: '24px',display: 'block'}} onClick={() => {viewFile(img.id);}}><PaperClipIcon/></a>))}</div> : null;
    }

    const accept = (id) => {
        getRequest(`/api/gauge_calibrations/deleteProtocol/${id}`)
            .then((response) => {
              toast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Súbor bol úspešne vymazaný!" });
              getRequest("/api/gauge_calibrations").then((response) => {
                dispatch({type:'load_gauge_calibrations',newGaugeCalibrations:response.data});
            });
              getImages();
            }).catch((reason) => {
                alert(reason);
                toast.current.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Súbor sa nepodarilo vymazať!', life: 3000 });
            })
      };

      const reject = () => {
          toast.current.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavka bola zrušená!', life: 3000 });
      }

    function deleteFiles(img){
        confirmDialog({
            message: 'Prajete si vymazať tento súbor?',
            header: 'Potvrdenie vymazania',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Nie',
            rejectLabel: 'Áno',
            accept:reject,
            reject:() => accept(img.id)
        });
    }

    const acceptCalibration = () => {
        let rows_to_delete = [];
        rows_to_delete = ref.current.getSelectedData();
        rows_to_delete.forEach(element => {
          console.log(element);
          deleteRequest(`/api/gauge_calibrations/${element.id}`)
              .then((response) => {
                toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne vymazaný!" });
                getRequest("/api/gauge_calibrations").then((response) => {
                  dispatch({type:'load_gauge_calibrations',newGaugeCalibrations:response.data});
              });
              }).catch((reason) => {
                  alert(reason);
                  toast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Záznam sa nepodarilo vymazať!', life: 3000 });
              })
        });
    }

    const confirm = () => {
        confirmDialog({
            message: 'Prajete si vymazať tento záznam?',
            header: 'Potvrdenie vymazania',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Nie',
            rejectLabel: 'Áno',
            accept: reject,
            reject: acceptCalibration
        });
    };
      

    const [columns,setColumns] = useState([
        { formatter:"rowSelection", titleFormatter:"rowSelection", hozAlign:"center",width: 20,headerSort:false},
        { hozAlign:"center",width: 20,headerSort:false,formatter: reactFormatter(<EditRowFormatter/>),resizable:false},
        { title: 'Kalibroval', field: 'custom_validator_name',headerFilter:"input",width: 200, formatter: reactFormatter(<Validator/>)},
        { title: 'Dátum kalibrácie', field: 'custom_calibration_date',editor: "input",width: 200,headerFilter:"input",formatter: reactFormatter(<CalibrationDate/>)},
        { title: 'Číslo kal. protokolu', field: 'calibration_number_protocol',headerFilter:"input",width: 200},
        { title: 'Dátum nasl. kalibrácie', field: 'next_calibration_date',headerFilter:"input",width: 200,formatter: reactFormatter(<NextCalibrationDate/>)},
        { title: 'Výsledok overenia', field: 'verification_result',editor: "input",headerFilter:"input",width: 200},
        { title: 'Záznam vykonal', field: 'contractor.name',editor: "input",headerFilter:"input",width: 200},
        { title: 'Poznámka', field: 'description',editor: "input",headerFilter:"input",width: 200},
        { field: 'calibration_date',visible:false,sorter: "date", sorterParams: { format: "yyyy-MM-dd",alignEmptyValues:"bottom",}},
        { title: 'Protokol', field: 'protocol',headerFilter:"input",width: 200,formatter: reactFormatter(<Protocol/>)},
    ]);

    function setMask(item,value){
        const columnVisible = {...columnsVisible};
       columnVisible[item] = value;
       console.log(columnVisible);
        for(const val in columnVisible) {
          if(columnVisible[val]==false){
            ref.current.hideColumn(`${val}`);
          }
          else{
            ref.current.showColumn(`${val}`);
          }
        }
        setColumnsVisible(columnVisible);
      }

      const itemRenderer = (item, options) => (
        <a className="flex align-items-center px-3 py-2 cursor-pointer items-center bg-[#333] text-white w-[180px]" onClick={options.onClick}>
            <span className={`${item.icon} text-primary`} />
            <span className={`mx-2 ${item.items && 'font-semibold'}`}>{item.label}</span>
            {item.shortcut && <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{item.shortcut}</span>}
        </a>
      );
    
      const [expandedKeys, setExpandedKeys] = useState({});
    
      const handleClickOutside = (event) => {
        if (overlayRef.current && !overlayRef.current.contains(event.target)) {
            setExpandedKeys({});
        }
      };
    
      useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);
  
      const items = [
      {
        key: '0',
        label: 'Akcie',
        icon: 'pi pi-sliders-v',
        template: itemRenderer,
        items: [
            {
                key: '0_1',
                label: 'Nastaviť filter',
                icon: 'pi pi-filter',
                command: () => {setUserMaskFilter("GaugeCalibrationAdd","set_filters",null,ref.current.getHeaderFilters(),null);},
                template: itemRenderer,
            },
              {
                key: '0_2',
                label: 'Zrušiť filter',
                icon: 'pi pi-filter-slash',
                command: () => {setUserMaskFilter("GaugeCalibrationAdd","set_filters",null,[],null);ref.current.clearHeaderFilter();},
                template: itemRenderer,
            },
            {
                key: '0_3',
                label: 'Nastaviť masku',
                icon: 'pi pi-server',
                command: () => {setMaskVisible(true);OverflowMenuProvider.setVisible(null);setExpandedKeys({});},
                template: itemRenderer,
            },
            {
              key: '0_4',
              label: 'Notifikácie',
              icon: 'pi pi-envelope',
              command: () => {showChosenNotifications();OverflowMenuProvider.setVisible(null);},
              template: itemRenderer,
          },
        ]
      }
    ];

    function showChosenNotifications(){
        let db_chosen_notifications = state.chosen_notifications?.filter((item => item.type == "otk_int"));
        if(db_chosen_notifications?.length>0){
          setChosenNotifications(JSON.parse(db_chosen_notifications[0].data));
        }
        else{
          setChosenNotifications([]);
        }
        setNotificationVisible(true);
      }
    
      function saveChosenNotifications(){
          let formData = new FormData();
          formData.append("type","gauge_calibrations");
          formData.append("data", JSON.stringify(chosenNotifications));
          postRequest('/api/chosen_notifications', formData,true)
          .then((response) => {
              if(response.status == 201){
                dispatch({type:'load_chosen_notifications',newChosenNotifications:response.data});
                let mytoast = toast.current ? toast : ownToast;
                mytoast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Stav aktualizovaný!" });
              }else{
                let mytoast = toast.current ? toast : ownToast;
                mytoast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavku sa nepodarilo vykonať!', life: 3000 });
              }
          }).catch((reason) => {
                console.log(reason);
                alert("Daný úkon sa nepodaril!");
          });
          setNotificationVisible(false);
      }
    
      function changeMarkings(value){
        if(value){
          const columnVisible = {...columnsVisible};
          for(let val in columnVisible) {
            if(columnVisible[val]!=="specific_id" || columnVisible[val]!=="editor" || columnVisible[val]!=="end_of_validity"){
              columnVisible[val]=true;
              ref.current.showColumn(`${val}`);
            }
          };
          setColumnsVisible(columnVisible);
        }
        else{
          const columnVisible = {...columnsVisible};
          for(let val in columnVisible) {
            if(columnVisible[val]!=="specific_id" || columnVisible[val]!=="editor"|| columnVisible[val]!=="end_of_validity"){
              columnVisible[val]=false;
              ref.current.hideColumn(`${val}`);
            }
          };
          setColumnsVisible(columnVisible);
        }
      }


    return ( 
        <>
        <Toast ref={ownToast}/>
            <div>
            <div className='w-full h-10 flex flex-row bg-[#333]'>
            <IntersectionObserverWrapper>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="add"><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => addNewCalibration()}><AddIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="copy"><button className="disabled:opacity-25" disabled={copyIcon} onClick={() => copyGaugeCalibration()}><CopyIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="edit"><button className="disabled:opacity-25" disabled={editIcon} onClick={() => editNewGaugeCalibration()}><EditIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="delete"><button className="disabled:opacity-25" disabled={deleteIcon} onClick={confirm}><DeleteIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="vert"><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="first"><button className="disabled:opacity-25" onClick={() => scrollToFirst()} disabled={scrollIcons}><BackToFirstIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="back"><button className="disabled:opacity-25" onClick={() => scrollUp()} disabled={scrollIcons}><BackIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="forward"><button className="disabled:opacity-25" onClick={() => scrollDown()} disabled={scrollIcons}><ForwardIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="last"><button className="disabled:opacity-25" onClick={() => scrollToLast()} disabled={scrollIcons}><ForwardToLastIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="vert"><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="favorite"><button className="disabled:opacity-25" onClick={() => openFavoriteDialog("Upraviť kalibráciu meradla","GaugeCalibrationAdd")}><FavoriteIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="price"><button onClick={() => setFastView("GaugeCalibrationAdd",columnsVisible,columns)}><PriceIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="vert"><VerticalLineIcon/></div>
          <div className="card flex justify-content-center z-[100]" data-targetid="actions" ref={overlayRef}>
            <PanelMenu model={items} expandedKeys={expandedKeys} onExpandedKeysChange={setExpandedKeys} className="w-full md:w-20rem"/>
          </div>
          </IntersectionObserverWrapper>
        </div>
        <Splitter className='h-[calc(100vh_-_177px)]' layout="vertical" pt={{ gutter: { className: 'bg-[#333] h-8' },gutterHandler: { className: 'bg-secondary ' } }}>
    <SplitterPanel className="flex align-items-center justify-content-center overflow-y-auto " minSize={10} size={60}>
        <form onSubmit={formik.handleSubmit} className='w-full'>
        <div className="relative flex flex-col overflow-x">
          <div className='xl:grid grid-cols-3 gap-1 mb-2'>
            <div className="w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md">
                <h1 className="text-3xl font-semibold text-left text-zinc-900">
                   EVIDENČNÁ KARTA MERADIEL
                </h1>
                {formType=="edit" && <label className="block text-md font-semibold text-gray-800">Por.č.: {gauge.specific_id}</label>}
                      <div>
                        <label
                            htmlFor="gauge_name"
                            className="block text-md font-semibold text-gray-800"
                        >
                            NÁZOV MERADLA
                        </label>
                        <input
                            name='gauge_name'
                            type="text"
                            readOnly={true}
                            value={gauge_calibration.gauge_name}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-gray-200 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="inventory_number"
                            className="block text-md font-semibold text-gray-800"
                        >
                            INVENTÁRNE ČÍSLO
                        </label>
                        <input
                            name='inventory_number'
                            type="text"
                            readOnly={true}
                            value={gauge_calibration.inventory_number}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-gray-200 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                    
                      <div>
                        <label
                            htmlFor="gauge_status"
                            className="block text-md font-semibold text-gray-800"
                        >
                            ŠTATÚT MERADLA
                        </label>
                        <select 
                        name='gauge_status'
                        disabled={true}
                        value={gauge_calibration.gauge_status}
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-gray-200 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                          <option value="Kalibrované">Pracovné kalibrované meradlo</option>
                          <option value="Informatívne">Informatívne meradlo</option>
                          <option value="Vyradené">Vyradené meradlo</option>
                        </select>
                        </div>
                        <div>
                        <label
                            htmlFor="max_calibration_interval"
                            className="block text-md font-semibold text-gray-800 mt-2"
                        >
                            MAX. KALIBRAČNÝ INTERVAL
                        </label>
                        <select 
                        name='max_calibration_interval'
                        disabled={true}
                        value={gauge_calibration.max_calibration_interval}
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-gray-200 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                          <option value="0">0</option>
                          <option value="3">3 mesiace</option>
                          <option value="6">6 mesiacov</option>
                          <option value="12">12 mesiacov</option>
                          <option value="24">24 mesiacov</option>
                          <option value="48">48 mesiacov</option>
                          <option value="60">60 mesiacov</option>
                        </select>
                        </div>        
            </div>
            <div className="w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md">
            <div>
                        <label
                            htmlFor="serial_number"
                            className="block text-md font-semibold text-gray-800"
                        >
                            VÝROBNÉ ČÍSLO
                        </label>
                        <input
                            name='serial_number'
                            type="text"
                            readOnly={true}
                            value={gauge_calibration.serial_number}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-gray-200 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="purchase_date"
                            className="block text-md font-semibold text-gray-800"
                        >
                             DÁTUM NÁKUPU
                        </label>
                        <input
                            name='purchase_date'
                            type="text"
                            readOnly={true}
                            value={gauge_calibration.purchase_date}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-gray-200 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div> 
                    <div>
                        <label
                            htmlFor="state"
                            className="block text-md font-semibold text-gray-800"
                        >
                            LOKALITA
                        </label>
                        <select 
                        name='state'
                        disabled={true}
                        value={gauge_calibration.state}
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-gray-200 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                          <option value="Na pracovisku">Na pracovisku</option>
                          <option value="Na kalibrácií"> Na kalibrácií</option>
                          <option value="Na oprave">Na oprave</option>
                        </select>
                        </div>
                        <div>
                        <label
                            htmlFor="manufacturer"
                            className="block text-md font-semibold text-gray-800"
                        >
                             VÝROBCA MERADLA
                        </label>
                        <input
                            name='manufacturer'
                            type="text"
                            readOnly={true}
                            value={gauge_calibration.manufacturer}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-gray-200 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="images"
                            className="block text-md font-semibold text-gray-800"
                        >
                            PREDLOHA:
                        </label>
                        {templates!=='' && templates.map((img) => (<table className='w-full border border-separate border-gray-400 rounded-md bg-gray-100 mb-4'>
                            <tr>
                                <td className='text-black h-10'><div className='flex flex-row p-2'>
                                    <div onClick={() => setVisibleFiles(true)}>
                                        <embed src={img.url+'#view=FitH&scrollbar=0'} width='100px' height='65px'></embed>
                                    </div>
                                        <div className='flex flex-col ml-4'>
                                        <a style={{color:'blue',cursor:'pointer',marginBottom:'2px'}} onClick={() => {setVisibleFiles(true);setViewedImage(img)}}>{img.filename}</a>
                                        </div>
                                    </div></td>
                            </tr>
                        </table>))}
                        </div>        
            </div>
            <div className="w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md">
            <div>
                        <label
                            htmlFor="gauge_type"
                            className="block text-md font-semibold text-gray-800"
                        >
                            TYP MERADLA
                        </label>
                        <select 
                        name='gauge_type'
                        disabled={true}
                        value={gauge_calibration.gauge_type}
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-gray-200 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                          <option value="Digitálne">Digitálne</option>
                          <option value="Analógové"> Analógové</option>
                        </select>
                        </div>
                        <div>
                        <label
                            htmlFor="gauge_category"
                            className="block text-md font-semibold text-gray-800"
                        >
                            KATEGÓRIA MERADLA
                        </label>
                        <select 
                        name='gauge_category'
                        disabled={true}
                        value={gauge_calibration.gauge_category}
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-gray-200 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                          <option value="PMU-Pracovné meradlo">PMU-Pracovné meradlo</option>
                          <option value="SM-Skúšovné meradlo"> SM-Skúšovné meradlo</option>
                          <option value="ZM-Zálohové meradlo"> ZM-Zálohové meradlo</option>
                        </select>
                        </div>
                        <div>
                        <label
                            htmlFor="calibration_company_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            KALIBRAČNÁ FIRMA
                        </label>
                        <select 
                        name="calibration_company_id" 
                        value={gauge_calibration.calibration_company_id}
                        disabled={true}
                        className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-gray-200 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                          <option value="">Vyber kal. firmu</option>
                          {state.calibration_companies.map((calibration_company) => (
                            <option key={calibration_company.id} value={calibration_company.id}>{calibration_company.name}</option>
                            ))}
                        </select>
                        </div>
                        <div>
                        <label
                            htmlFor="gauge_description"
                            className="block text-md font-semibold text-gray-800"
                        >
                            POPIS MERADLA
                        </label>
                        <textarea
                        name='gauge_description'
                        readOnly={true}
                        value={gauge_calibration.gauge_description}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-gray-200 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>        
            </div>
            </div> 
            </div>
             </form>
             </SplitterPanel>
    <SplitterPanel className="flex flex-col align-items-center justify-content-center w-full overflow-y-auto" size={40} style={{minHeight: '110px'}}>
    <ReactTabulator
      data={state.gauge_calibrations} 
      onRef={(r) => (ref.current = r.current)}
      className='h-full w-full' //176px h-[calc(100vh_-_176px)]
      columns={columns}
      events={{
        rowSelectionChanged: onSelectRow,
      }}
      options={{rowHeight:30,
      printAsHtml:true,printHeader:"<h1>Zoznam kalibrácií meradiel<h1>",printRowRange:"active",
      autoResize:false,
      initialFilter:[
        {field:"gauge_id", type:"=", value:gauge.id}
      ],
      initialSort:[
        {column:"calibration_date", dir:"desc"}, //sort by this first
      ]
      }}/>
    </SplitterPanel>
</Splitter> 
    <Dialog header="Upraviť kalibráciu" visible={visibleCalibration} style={{ width: '40vw' }} onHide={() => hideEditCalibration()}>
    <form onSubmit={formikUpdate.handleSubmit}>
            <div>   
                <h3 className="text-xl font-semibold text-left text-zinc-900 mb-2">
                   ZÁZNAM KALIBRÁCIE MERADLA
                </h3>
                        <label
                            htmlFor="validator_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            KALIBROVAL
                        </label>
                        <select
                        name="validator_id" onChange={formikUpdate.handleChange} value={formikUpdate.values.validator_id}
                        className="block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                            <option value=''>Interný zamestnanec</option>
                          {state.calibration_companies.map((calibration_company) => (
                            <option key={calibration_company.id} value={calibration_company.id}>{calibration_company.name}</option>
                            ))}
                        </select>
                        </div>
                        <div>
                        <label
                            htmlFor="calibration_date"
                            className="block text-md font-semibold text-gray-800"
                        >
                            DÁTUM
                        </label>
                        <input required
                            name='calibration_date'
                            type="date"
                            onChange={formikUpdate.handleChange} value={formikUpdate.values.calibration_date}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="calibration_number_protocol"
                            className="block text-md font-semibold text-gray-800"
                        >
                            ČÍSLO KAL. PROTOKOLU
                        </label>
                        <input
                            name='calibration_number_protocol'
                            type="text"
                            onChange={formikUpdate.handleChange} value={formikUpdate.values.calibration_number_protocol}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="verification_result"
                            className="block text-md font-semibold text-gray-800"
                        >
                            VÝSLEDOK OVERENIA
                        </label>
                        <select required
                        name='verification_result'
                        onChange={formikUpdate.handleChange} value={formikUpdate.values.verification_result}
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                          <option value="OK">OK</option>
                          <option value="NOK">NOK</option>
                        </select>
                        </div> 
                        <div>
                        <label
                            htmlFor="contractor_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            ZÁZNAM VYKONAL
                        </label>
                        <select required
                        name="contractor_id" onChange={formikUpdate.handleChange} value={formikUpdate.values.contractor_id}
                        className="block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                          <option value="">Vyber zamestnanca</option>
                          {state.employees.map((employee) => (
                            <option key={employee.id} value={employee.id}>{employee.name}</option>
                            ))}
                        </select>
                        </div>
                        <div>
                        <label
                            htmlFor="description"
                            className="block text-md font-semibold text-gray-800"
                        >
                            POZNÁMKA
                        </label>
                        <input
                            name='description'
                            type="text"
                            onChange={formikUpdate.handleChange} value={formikUpdate.values.description}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="gauge_category"
                            className="block text-md font-semibold text-gray-800"
                        >
                            PROTOKOL
                        </label>
                        <input
                            name='files' 
                            ref={updateFileListRef}
                            onChange={handleUpdateFileChange}
                            className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                            type='file'
                            multiple="multiple"
                            id='file'/>
                        </div> 
                        {images!=='' && images.map((img) => ( <div className='flex flex-row p-2'>
                            <div onClick={() => setVisibleFiles(true)}>
                                <embed src={img.url+'#view=FitH&scrollbar=0'} width='100px' height='65px'></embed>
                            </div>
                                <div className='flex flex-col ml-4'>
                                <a style={{color:'blue',cursor:'pointer',marginBottom:'2px'}} onClick={() => {setVisibleFiles(true);setViewedImage(img)}}>{img.filename}</a>
                                <button type="button" className='w-fit flex flex-row px-2 py-1 border rounded-md bg-red-900' onClick={() => {setViewedImage(img);deleteFiles(img)}} ><DeleteIcon/><span className='text-white ml-1'>Vymazať</span></button>
                                </div>
                            </div>))}
                            <button type="submit" className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Uložiť</button>         
            </form>
      </Dialog>
      <Dialog header="Náhľad PDF súboru" visible={visibleFiles} onHide={() => setVisibleFiles(false)} style={{ width: '80vw',height:'90vh',backgroundColor: 'transparent',boxShadow: 'none'}}>
        <iframe src={viewedImage.url} className='w-full h-full'></iframe>
        </Dialog>
        <Dialog header="Nová kalibrácia" visible={showGaugeCalibrationAdd} style={{ width: '40vw' }} onHide={() => setShowGaugeCalibrationAdd(false)}>
        <form onSubmit={formik.handleSubmit}>
            <div>   
                        <label
                            htmlFor="validator_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            KALIBROVAL
                        </label>
                        <select
                        name="validator_id" onChange={formik.handleChange} value={formik.values.validator_id}
                        className="block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                            <option value=''>Interný zamestnanec</option>
                          {state.calibration_companies.map((calibration_company) => (
                            <option key={calibration_company.id} value={calibration_company.id}>{calibration_company.name}</option>
                            ))}
                        </select>
                        </div>
                        <div>
                        <label
                            htmlFor="calibration_date"
                            className="block text-md font-semibold text-gray-800"
                        >
                            DÁTUM
                        </label>
                        <input required
                            name='calibration_date'
                            type="date"
                            onChange={formik.handleChange} value={formik.values.calibration_date}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="calibration_number_protocol"
                            className="block text-md font-semibold text-gray-800"
                        >
                            ČÍSLO KAL. PROTOKOLU
                        </label>
                        <input
                            required
                            name='calibration_number_protocol'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.calibration_number_protocol}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="verification_result"
                            className="block text-md font-semibold text-gray-800"
                        >
                            VÝSLEDOK OVERENIA
                        </label>
                        <select required
                        name='verification_result'
                        onChange={formik.handleChange} value={formik.values.verification_result}
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                          <option value="OK">OK</option>
                          <option value="NOK">NOK</option>
                        </select>
                        </div> 
                        <div>
                        <label
                            htmlFor="contractor_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            ZÁZNAM VYKONAL
                        </label>
                        <select required
                        name="contractor_id" onChange={formik.handleChange} value={formik.values.contractor_id}
                        className="block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                          <option value="">Vyber zamestnanca</option>
                          {state.employees.map((employee) => (
                            <option key={employee.id} value={employee.id}>{employee.name}</option>
                            ))}
                        </select>
                        </div>
                        <div>
                        <label
                            htmlFor="description"
                            className="block text-md font-semibold text-gray-800"
                        >
                            POZNÁMKA
                        </label>
                        <input
                            name='description'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.description}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="gauge_category"
                            className="block text-md font-semibold text-gray-800"
                        >
                            PROTOKOL
                        </label>
                        <input
                            required={gauge_calibration.gauge_status == "Kalibrované" ? true : false}
                            name='files' 
                            ref={fileListRef}
                            onChange={handleFileChange}
                            className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                            type='file'
                            multiple="multiple"
                            id='file'/>
                        </div> 
                        {images!=='' && images.map((img) => ( <div className='flex flex-row p-2'>
                            <div onClick={() => setVisibleFiles(true)}>
                                <embed src={img.url+'#view=FitH&scrollbar=0'} width='100px' height='65px'></embed>
                            </div>
                                <div className='flex flex-col ml-4'>
                                <a style={{color:'blue',cursor:'pointer',marginBottom:'2px'}} onClick={() => {setVisibleFiles(true);setViewedImage(img)}}>{img.filename}</a>
                                <button type="button" className='w-fit flex flex-row px-2 py-1 border rounded-md bg-red-900' onClick={() => {setViewedImage(img);deleteFiles(img)}} ><DeleteIcon/><span className='text-white ml-1'>Vymazať</span></button>
                                </div>
                            </div>))}
                            <button type="submit" className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Uložiť</button>         
            </form>
            </Dialog>
            <Dialog header="Nastaviť masku" visible={maskVisible} style={{ width: '70vw' }} onHide={() => setMaskVisible(false)}>
        <div className="flex flex-col">
        <button type="button" onClick={() => {changeMarkings(!markUnmark);setMarkUnmarked(!markUnmark);}} className='m-1 px-4 py-2 text-center tracking-wide text-white transition-colors duration-200 transform bg-zinc-700 rounded-md hover:bg-zinc-500'>{markUnmark ? "ODZNAČ VŠETKO" : "OZNAČ VŠETKO"}</button>
        {columns.map(function(item, index){ 
          if(index<2 || index==9) return null;
                    return <div 
                        key={index} 
                        className="flex flex-row border border-black rounded-md p-2 m-1"
                    > 

                         <Checkbox onChange={(e) => setMask(item.field,e.checked)} checked={columnsVisible[item.field]}></Checkbox><span className="ml-2">{item.title}</span>
                          
                        {/* Use the React icon component */} 
                    </div> 
                })} 
            <button onClick={() => {setUserMaskFilter("GaugeCalibrationAdd","set_columns",columnsVisible,null,null);setMaskVisible(false);}} className='m-1 px-4 py-2 text-center tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'>ULOŽIŤ</button>
        </div>
      </Dialog>
      <Dialog header="Nastaviť notifikácie" visible={notificationVisible} style={{ width: '70vw' }} onHide={() => setNotificationVisible(false)}>
            <div className="flex flex-col">
                         <div  
                            className="flex flex-row border border-black rounded-md p-2 m-1" 
                          > 
                            <Checkbox name='Nová' value='Nová' checked={chosenNotifications.includes('Nová')}
                            onClick={(e) => {
                                let edit = [...chosenNotifications];
                                console.log(e,edit);
                                if (e.checked){
                                    edit.push(e.value);
                                }
                                else
                                    edit.splice(edit.indexOf(e.value), 1);
                                setChosenNotifications(edit);
                            }}></Checkbox>
                            <label htmlFor='Nová' className="ml-2">Nový záznam</label>
                        </div> 
                        <div  
                            className="flex flex-row border border-black rounded-md p-2 m-1" 
                        > 
                            <Checkbox name='Zmena stavu' value='Zmena stavu' checked={chosenNotifications.includes('Zmena stavu')}
                            onClick={(e) => {
                                let edit = [...chosenNotifications];
                                console.log(e,edit);
                                if (e.checked){
                                    edit.push(e.value);
                                }
                                else
                                    edit.splice(edit.indexOf(e.value), 1);
                                setChosenNotifications(edit);
                            }}></Checkbox>
                            <label htmlFor='Zmena stavu' className="ml-2">Zmena stavu</label>
                        </div>
                    
              <button type='button' onClick={() => saveChosenNotifications()} className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Uložiť</button>
            </div>
          </Dialog>
            </div>
            </> 
            );
} 
