import React,{useContext,useEffect, useState,useRef} from 'react';
import { getRequest, postRequest, putRequest,deleteRequest } from '../../api/ApiController';
import { DatabaseContext } from '../../redux/DatabaseStore';
import { ReactTabulator,reactFormatter } from 'react-tabulator';
import '../../assets/css/style.css';
import '../../assets/css/iconsmenu.css'
import { confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css"
import { AddIcon, EditIcon, CopyIcon, DeleteIcon, StepBackIcon, PrintIcon, SearchIcon, ExportIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon,EditRowIcon} from "../svg/svgIcons";
import {useReactToPrint} from "react-to-print";
import { InfinitySpin } from  'react-loader-spinner';
import { DateTime } from "luxon";
import AuthContext from '../../components/AuthContext';


export default function EmployeeList(){

  const [state,dispatch] = useContext(DatabaseContext);
  const {user} = useContext(AuthContext);

    const [stepBackIcon,setStepBackIcon] = useState(true);
    const [editIcon,setEditIcon] = useState(true);
    const [deleteIcon,setDeleteIcon] = useState(true);
    const [favoriteIcon,setFavoriteIcon] = useState(false);
    const [copyIcon,setCopyIcon] = useState(true);
    const [insertIcon,setInsertIcon] = useState(!user.permissions.includes("add users"));

    const [visibleSearch,setVisibleSearch] = useState(false);
    const [visibleLoading,setVisibleLoading] = useState(false);
    const [searchValue,setSearchValue] = useState('');
    const [scrollValue,setScrollValue] = useState(1);
    const [favoriteVisible,setFavoriteVisible] = useState(false);
    const [favoriteGroupValue,setFavoriteGroupValue] = useState("");
    const [favoriteValue,setFavoriteValue] = useState("");

    const [editFormEmployee, setEditFormEmployee] = useState([]);

    const ref = useRef(null);
    const toast = useRef(null);
    const elementRef = useRef(null);
    const componentRef = useRef(null);

    useEffect(() => {
      if (state.employees && ref.current && state.employees_reload) {
        //ref.current.updateData(state.employees);
        console.log("EMP UPDATE");
        setEditIcon(true);
        setDeleteIcon(true);
        setCopyIcon(true);
        dispatch({type:'employees_reload',value:false});
        //lepsie vymysliet reload
        //dat hook na to
      }
    },[state.employees,ref.current,state.employees_reload]);
  
  
  function matchAny(data, filterParams) {
    //data - the data for the row being filtered
    //filterParams - params object passed to the filter
    //RegExp - modifier "i" - case insensitve

    var match = false;
    const regex = RegExp(filterParams.value, 'i');

    for (var key in data) {
        if (regex.test(data[key]) === true) {
            match = true;
        }
    }
    return match;
  }

  function searchTable(){
    ref.current.setFilter(matchAny, { value: searchValue});
    if (searchValue === " ") {
      ref.current.clearFilter()
    }
    setVisibleSearch(false);
  }

    const accept = () => {
        let rows_to_delete = [];
        rows_to_delete = ref.current.getSelectedData();
        rows_to_delete.forEach(element => {
          console.log(element);
          deleteRequest(`/api/employees/${element.id}`)
              .then((response) => {
                toast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne vymazaný!" });
                getRequest("/api/employees").then((response) => {
                  dispatch({type:'load_employees',newEmployees:response.data});
                  dispatch({type:'employees_reload',value:true});
                  dispatch({type:'employees_reload',value:false});
              });
              }).catch((reason) => {
                  alert(reason);
                  toast.current.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Záznam sa nepodarilo vymazať!', life: 3000 });
              })
        });
        getRequest("/api/employees").then((response) => {
          dispatch({type:'load_employees',newErrorRecords:response.data});
        });   
        //toast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne vymazaný!" });
    }

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavka bola zrušená!', life: 3000 });
    }

    const confirm = () => {
        confirmDialog({
            message: 'Prajete si vymazať tento záznam?',
            header: 'Potvrdenie vymazania',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Áno',
            rejectLabel: 'Nie',
            accept,
            reject
        });
    };

  const rowClick = (e, row) => {
    console.log(e, row);
 };

  const onSelectRow = (data, rows) => {
    setEditFormEmployee(data);
    setEditIcon(true);
    setCopyIcon(true);
    setDeleteIcon(true);
    if(ref.current.getSelectedData().length>0){
      if(ref.current.getSelectedData().length == 1){
        if(user.permissions.includes("add users"))setCopyIcon(false);
        if(user.permissions.includes("edit users"))setEditIcon(false);
      }
      if(user.permissions.includes("delete users"))setDeleteIcon(false);
    }
  };

  function onEditRow(employee){
    dispatch({type:'create_new_tab',name:'Upraviť zamestnanca',page:'EmployeeAdd',props:{employee: employee,type:'edit',not_last_used: true},mask:'glo004'})
  };
  
  function EditRowFormatter(props) {
    const rowData = props.cell._cell.row.data;
    return <div className='flex items-center justify-center w-full h-full'><button type='button' onClick={() => onEditRow(rowData)}><EditRowIcon/></button></div>;
  }

  const columns = [
    { formatter:"rowSelection", titleFormatter:"rowSelection", hozAlign:"center",width: 20,headerSort:false,resizable:false},
    { hozAlign:"center",width: 20,headerSort:false,formatter: reactFormatter(<EditRowFormatter/>),resizable:false},
    { title: 'Meno zamestnanca', field: 'name',headerFilter:"input",resizable:false},
    { title: 'Popis zamestnanca', field: 'description',headerFilter:"input",resizable:false},
    { title: 'Pridelené ID', field: 'defined_id',headerFilter:"input",resizable:false},
    { title: 'Používateľské meno', field: 'user.username',headerFilter:"input",resizable:false},
    { title: 'Oddelenie', field: 'department.name', headerFilter:"select", headerFilterParams:{values:state.departments.map(department => `${department.name}`)},resizable:false}
  ];

  async function scrollToFirst(){
    let res = state.employees[0].id;
    ref.current.scrollToRow(res, "top", true);
    setScrollValue(1);
  }

  async function scrollUp(){
    let visible_rows = Math.floor((elementRef.current.clientHeight-68)/30-1);
    if((scrollValue-visible_rows+1) >= 0){
      let res = state.employees[scrollValue-visible_rows+1].id;
      ref.current.scrollToRow(res, "top", true);
      setScrollValue(scrollValue-visible_rows);
    }
    else if(scrollValue > 0 && scrollValue <= visible_rows){
      let res = state.employees[0].id;
      ref.current.scrollToRow(res, "top", true);
      setScrollValue(1);
    }
  }

  async function scrollDown(){
    let visible_rows = Math.floor((elementRef.current.clientHeight-68)/30-1);
    let rows = ref.current.getRows(true).length;
    if(scrollValue===1){
      let res = state.employees[scrollValue+visible_rows-1].id;
      ref.current.scrollToRow(res, "top", true);
      setScrollValue(scrollValue+visible_rows);
    }
    else if((scrollValue+visible_rows) <= rows){
      let res = state.employees[scrollValue+visible_rows-1].id;
      let rows = ref.current.getRows(true).length;
      setScrollValue(scrollValue+visible_rows);
      if(res > rows-visible_rows){
        res = state.employees[rows-visible_rows].id;
        setScrollValue(rows-visible_rows);
      }
      ref.current.scrollToRow(res, "top", true);
    }
  }

  async function scrollToLast(){
    let visible_rows = Math.floor((elementRef.current.clientHeight-68)/30-1);
    let rows = ref.current.getRows(true).length;
    let res = state.employees[rows-visible_rows]?.id;
    ref.current.scrollToRow(res, "top", true);
    setScrollValue(rows-visible_rows);
  }
  function addNewEmployee(){
    dispatch({type:'create_new_tab',name:'Nový zamestnanec',page:'EmployeeAdd',props:{employee: null,type:'add'},mask:'glo004'})
  }
  function copyEmployee(){
    dispatch({type:'create_new_tab',name:'Nový zamestnanec',page:'EmployeeAdd',props:{employee: ref.current.getSelectedData()[0],type:'copy',not_last_used: true},mask:'glo004'})
  }
  function editEmployee(){
    dispatch({type:'create_new_tab',name:'Upraviť zamestnanca',page:'EmployeeAdd',props:{employee: ref.current.getSelectedData()[0],type:'edit',not_last_used: true},mask:'glo004'})
  }

  function confirmFavoriteDialog(){
    if(favoriteGroupValue==""){
      alert("Vyberte skupinu!");
      return;
    }
    else if(favoriteGroupValue=="nova"){
      if(favoriteValue==""){
        alert("Napíšte názov novez skupiny!");
        return;
      }
      else{
        //VYTVORIT SKUPINU AJ FAVORITA
        setFavoriteVisible(false);
        setVisibleLoading(true);
        let formData = new FormData();
        let new_group;
        formData.append("name", favoriteValue);
        postRequest(`/api/favorite_groups`, formData,true)
        .then((response) => {
          let formData2 = new FormData();
          formData2.append("name", "Zamestnanci");
          formData2.append("page", "EmployeeList");
          formData2.append("favorite_group_id", response.data.id);
          postRequest(`/api/favorites`, formData2,true)
          .then((response) => {
              getRequest("/api/my_favorite_groups").then((response) => {
                dispatch({type:'load_favorites',newFavorites:response.data});
                setVisibleLoading(false);
            });
          }).catch((reason) => {
              setVisibleLoading(false);
              if(!navigator.online){
                  alert("Ste offline, dáta budu odoslané po znovupripojení!");
              }
              else{
                  alert("Daný úkon sa nepodaril!");
              }
          })
        }).catch((reason) => {
            setVisibleLoading(false);
            if(!navigator.online){
                alert("Ste offline, dáta budu odoslané po znovupripojení!");
            }
            else{
                alert("Daný úkon sa nepodaril!");
            }
        })
      }
    }
    else{
      //VYTVORIT FAVORITA a pridat do skupiny
      setFavoriteVisible(false);
      setVisibleLoading(true);
      let formData2 = new FormData();
      formData2.append("name", "Zamestnanci");
      formData2.append("page", "EmployeeList");
      formData2.append("favorite_group_id", favoriteGroupValue);
      postRequest(`/api/favorites`, formData2,true)
      .then((response) => {
          getRequest("/api/my_favorite_groups").then((response) => {
            dispatch({type:'load_favorites',newFavorites:response.data});
            setVisibleLoading(false);
        });
      }).catch((reason) => {
          setVisibleLoading(false);
          if(!navigator.online){
              alert("Ste offline, dáta budu odoslané po znovupripojení!");
          }
          else{
              alert("Daný úkon sa nepodaril!");
          }
      })
    }
  }

    return(
      <>
      <div className='w-full h-10 flex flex-row bg-[#333]'>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertIcon} onClick={() => addNewEmployee()}><AddIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={editIcon} onClick={() => editEmployee()}><EditIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={copyIcon} onClick={() => copyEmployee()}><CopyIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={confirm} disabled={deleteIcon}><DeleteIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button onClick={() => ref.current.print(false, true)}><PrintIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button onClick={() => setVisibleSearch(true)}><SearchIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button onClick={() => ref.current.download("xlsx", "data.xlsx", {sheetName:"MyData"})}><ExportIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button onClick={() => scrollToFirst()}><BackToFirstIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button onClick={() => scrollUp()}><BackIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button onClick={() => scrollDown()}><ForwardIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button onClick={() => scrollToLast()}><ForwardToLastIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={favoriteIcon} onClick={() => setFavoriteVisible(true)}><FavoriteIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button><PriceIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
      </div>
<div className='px-1 bg-white rounded-md shadow-md'>
<div className="flex flex-col overflow-x-auto no-scrollbar min-h-[calc(100vh-177px)]">
<div className="sm:-mx-6 lg:-mx-8 overflow-hidden">
  <div className="inline-block w-full py-2 sm:px-6 lg:px-8" >
    <div ref={elementRef}>
      <ReactTabulator
      data={state.employees} 
      className='h-[calc(100vh_-_193px)]' //176px
      onRef={(r) => (ref.current = r.current)}
      columns={columns}
      events={{
        rowClick: rowClick,
        rowSelectionChanged: onSelectRow,
        rowUpdated:function(data){
                //data - the updated table data
                console.log("DATA WAS CHANGED");
        }
      }}
      options={{renderHorizontal:"virtual",rowHeight:30,movableColumns: true,
      printAsHtml:true,printHeader:"<h1>Zoznam zamestnancov<h1>",printRowRange:"active"}}/> 
    </div> 
  </div> 
</div> 
</div> 
<Dialog header="Hľadaj v zozname:" visible={visibleSearch} style={{ width: '40vw' }} onHide={() => setVisibleSearch(false)}>
            <div className="flex flex-columns">
              
              <input
                  value={searchValue} onChange={e => setSearchValue(e.target.value)}
                  type="text"
                  className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
              />
              <button onClick={() => searchTable()} className='w-16 ml-8 px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'><SearchIcon/></button>
            </div>
          </Dialog>
      <Dialog visible={visibleLoading} style={{ width: '25vw',height:'25vh',backgroundColor: 'transparent',boxShadow: 'none'}} className='mypanel flex flex-row'  closable={false} draggable={false} showHeader={false}>
          <InfinitySpin 
          width='208'
          color="#000"
          />
          <p className='text-2xl font-bold text-center w-52 text-black'>Načítavam...</p>
      </Dialog>
      </div> 
 </>
    )

}