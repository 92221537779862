import React,{ createContext, useState ,useContext,useEffect,useRef} from "react";
import { getRequest,postRequest } from "../api/ApiController";
import { Dialog } from 'primereact/dialog';
import { InfinitySpin } from  'react-loader-spinner';
import { DatabaseContext } from "../redux/DatabaseStore";
import AuthContext from "./AuthContext";
import { ConfirmDialog } from "primereact/confirmdialog";
import Pusher from 'pusher-js';
import { Toast } from 'primereact/toast';
import { Sidebar } from 'primereact/sidebar';
import { Message } from 'primereact/message';
import { DeleteIcon } from "../pages/svg/svgIcons";
import { CloseMess} from "../pages/svg/svgIcons";
import { useLocation } from 'react-router-dom';
//import Quill from "quill";

/* Context, which serves to authenticate the user in the system */
const UtilContext = createContext();
export const UtilProvider = {};

export const UtilContextProvider = ({ children }) => {
  
    const { user } = useContext(AuthContext);
    const [state,dispatch] = useContext(DatabaseContext);

    const [dialogText,setDialogText] = useState(""); 
    const [visible,setVisible] = useState(false);
    const [visibleNotifications,setVisibleNotifications] = useState(false);
    const [favoriteVisible,setFavoriteVisible] = useState(false);
    const [favoriteGroupValue,setFavoriteGroupValue] = useState("");
    const [favoriteValue,setFavoriteValue] = useState("");
    const [favoriteName,setFavoriteName] = useState("");
    const [favoritePage,setFavoritePage] = useState("");
    const location = useLocation();

    const toast = useRef(null);
    const [toastPosition,setToastPosition] = useState("top-right");

    UtilProvider.setVisible = setVisible;
    UtilProvider.toast = toast;
    UtilProvider.setToastPosition = setToastPosition;

    async function fetchDatafromDB(){
        //console.log("NACITAM",user,state.user);
        if(!state.user){
            setDialogText("Načítavam");
            setVisible(true);
            //console.log("DB STORE USER",user);
            dispatch({type:'login_user',newUser: user});
            dispatch({type:'load_last_used'});
        }
        if(user){
            await getRequest("/api/auth/startRequest").then((response) => {
                dispatch({type:'load_employees',newEmployees:response.data.employees});
                dispatch({type:'load_departments',newDepartments:response.data.departments});
                dispatch({type:'load_fast_view',newFastView:response.data.fast_view});
                dispatch({type:'load_user_mask_filters',newUserMaskFilters:response.data.user_mask_filters});
                dispatch({type:'load_favorites',newFavorites:response.data.favorites});
                dispatch({type:'load_chosen_notifications',newChosenNotifications:response.data.chosen_notifications});
                dispatch({type:'load_notifications',newNotifications:response.data.notifications});
                dispatch({type:'load_support_tickets',newSupportTickets:response.data.support_tickets});
                if(user.permissions.includes('access users')){
                    dispatch({type:'load_roles',newRoles:response.data.roles});
                    dispatch({type:'load_users',newUsers:response.data.users});
                }
                if(user.permissions.includes('access priorities')){
                    dispatch({type:'load_priority_departments',newPriorityDepartments:response.data.priority_departments});
                    dispatch({type:'load_priorities',newPriorities:response.data.priorities});
                }
                if(user.permissions.includes('access otk-section1')){
                    dispatch({type:'load_customers',newCustomers:response.data.customers});
                    dispatch({type:'load_error_types',newErrorTypes:response.data.error_types});
                    dispatch({type:'load_groups',newGroups:response.data.groups});
                    dispatch({type:'load_offenders',newOffenders:response.data.offenders});
                    dispatch({type:'load_preventions',newPreventions:response.data.preventions});
                    dispatch({type:'load_suppliers',newSuppliers:response.data.suppliers});
                    dispatch({type:'load_error_records',newErrorRecords:response.data.error_records});
                    dispatch({type:'load_documentation',newDocumentation:response.data.documentation});
                    dispatch({type:'load_self_control',newSelfControl: response.data.self_control});
                    dispatch({type:'load_measurement_protocol',newMeasurementProtocol: response.data.measurement_protocol});
                    dispatch({type:'load_ndt_protocol',newNDTProtocol: response.data.ndt_protocol});
                    dispatch({type:'load_annealing_protocol',newAnnealingProtocol: response.data.annealing_protocol});
                    dispatch({type:'load_measure_complexities',newMeasureComplexities: response.data.measure_complexities});
                }
                if(user.permissions.includes('access otk-section2')){
                    dispatch({type:'load_summary_emails',newSummaryEmails:response.data.summary_emails});
                }
                if(user.permissions.includes('access painting')){
                    dispatch({type:'load_painting_conditions',newPaintingConditions:response.data.painting_conditions});
                    dispatch({type:'load_painting_intervals',newPaintingIntervals:response.data.painting_intervals});
                    dispatch({type:'load_weather',newWeather:response.data.weather});
                }
                if(user.permissions.includes('edit painting')){
                    dispatch({type:'load_painting_time_conditions',newPaintingTimeConditions:response.data.painting_time_conditions});
                    dispatch({type:'load_painting_employee_conditions',newPaintingEmployeeConditions:response.data.painting_employee_conditions});
                }
                if(user.permissions.includes('access calibration')){
                    dispatch({type:'load_gauges',newGauges:response.data.gauges});
                    dispatch({type:'load_gauge_calibrations',newGaugeCalibrations:response.data.gauge_calibrations});
                    dispatch({type:'load_calibration_companies',newCalibrationCompanies:response.data.calibration_companies});
                    dispatch({type:'load_calibration_company_certificates',newCalibrationCompanyCertificates:response.data.calibration_company_certificates});
                }
                if(location){
                    const query = new URLSearchParams(location.search);
                    console.log(location.search);
                    if(query.get("tab")=="PriorityList"){
                        let query_priority_id = query.get("priority_id") ? query.get("priority_id") : null;
                        let query_priority_problem_id = query.get("problem_id") ? query.get("problem_id") : null;
                        let query_props = { query_priority_id,query_priority_problem_id}
                        dispatch({type:'create_new_tab',name:'Zoznam Priorít',page:'PriorityList',props:{query_props:query_props}});
                    }
                    if(query.get("tab")=="InternaChyba"){
                        let query_error_record_id = query.get("error_record_id") ? query.get("error_record_id") : null;
                        let state_error_record_id = response.data.error_records.filter(error_record => error_record.type == 'Interná chyba').filter(record => record.id == query_error_record_id);
                        if(state_error_record_id.length>0){
                            dispatch({type:'create_new_tab',name:'UPRAVIŤ INT. CHYBA',page:'InternaChyba',props:{error_record: state_error_record_id[0],er_type:'edit'}})
                        }
                    }
                    if(query.get("tab")=="ExternaChyba"){
                        console.log("EXTERNA CHYBA")
                        let query_error_record_id = query.get("error_record_id") ? query.get("error_record_id") : null;
                        let state_error_record_id = response.data.error_records.filter(error_record => error_record.type == 'Externá chyba').filter(record => record.id == parseInt(query_error_record_id, 10));
                        if(state_error_record_id.length>0){
                            dispatch({type:'create_new_tab',name:'UPRAVIŤ EXT. CHYBA',page:'ExternaChyba',props:{error_record: state_error_record_id[0],er_type:'edit'}})
                        }
                    }
                    if(query.get("tab")=="Reklamacia"){
                        let query_error_record_id = query.get("error_record_id") ? query.get("error_record_id") : null;
                        let state_error_record_id = response.data.error_records.filter(error_record => error_record.type == 'Reklamácia').filter(record => record.id == query_error_record_id);
                        if(state_error_record_id.length>0){
                            dispatch({type:'create_new_tab',name:'UPRAVIŤ REKLAMÁCIU',page:'Reklamacia',props:{error_record: state_error_record_id[0],er_type:'edit'}})
                        }
                    }
                    if(query.get("tab")=="PaintingConditionAdd"){
                        let query_painting_condition_id = query.get("painting_condition_id") ? query.get("painting_condition_id") : null;
                        let state_painting_condition_id = response.data.painting_conditions.filter(condition => condition.id == query_painting_condition_id);
                        if(state_painting_condition_id.length>0){
                            dispatch({type:'create_new_tab',name:'Upraviť podmienky lakovne',page:'PaintingConditionAdd',props:{painting_condition: state_painting_condition_id[0],type:'edit'}})
                        }
                    }
                    if(query.get("tab")=="GaugeCalibrationAdd"){
                        let query_gauge_id = query.get("gauge_id") ? query.get("gauge_id") : null;
                        let state_gauge_id = response.data.gauges.filter(gauge => gauge.id == query_gauge_id);
                        if(state_gauge_id.length>0){
                            dispatch({type:'create_new_tab',name:'Upraviť kalibráciu meradla',page:'GaugeCalibrationAdd',props:{gauge: state_gauge_id[0],gauge_calibration: state_gauge_id[0],type:'edit'}})
                        }
                    }
                    if(query.get("tab")=="SupportTicketList"){
                        let query_ticket_id = query.get("ticket_id") ? query.get("ticket_id") : null;
                        if(query_ticket_id != null){
                            dispatch({type:'create_new_tab',name:'Zoznam žiadostí',page:'SupportTicketList',props:{ticket_id: query_ticket_id}});
                        }
                    }
                }
            });
        }
    }

    useEffect(() => {
        if (user !== null) {
            //console.log("USER WAS SET!",user);
            fetchDatafromDB().then(() => {                
                setVisible(false);
            });
        }  
      }, [user]);

      useEffect(() => {
        if (user !== null && state.chosen_notifications != null) {
            //console.log("USER WAS SET!",user);
            var pusher = new Pusher('798100b9a135677e9c73', {
                cluster: 'eu'
            });
            var channel = pusher.subscribe('isg-channel');
            channel.bind('isg-event', function(notification) {
                if(notification?.user_id!=user.id){
                    //console.log(notification,this.chosen_notifications);
                    let page = JSON.parse(notification.data.data)?.page;
                    let result = this.chosen_notifications.filter((chosen) => chosen.type == page);
                    if(page=="SupportTicketList"){
                        //console.log("Mentions",JSON.parse(notification.data.data)?.mentions);
                        if(JSON.parse(notification.data.data)?.mentions.includes(user.id)){
                            dispatch({type:'add_notification',value:notification});
                            getRequest("/api/support_tickets").then((response) => {
                                dispatch({type:'load_support_tickets',newSupportTickets:response.data});
                            });
                        }
                    }
                    else if(result.length>0){
                        let chosen_detail = JSON.parse(result[0].data);
                        if(page=="PriorityList"){
                            if(chosen_detail?.includes(JSON.parse(notification.data.data)?.department_id) || chosen_detail?.includes(JSON.parse(notification.data.data)?.offender_department_id)){
                                dispatch({type:'add_notification',value:notification});
                                getRequest("/api/priorities").then((response) => {
                                    dispatch({type:'load_priorities',newPriorities:response.data});
                                });
                            }
                        }
                        else{
                            if(chosen_detail?.includes(JSON.parse(notification.data.data)?.state))dispatch({type:'add_notification',value:notification});
                        }
                    }
                }
            },{chosen_notifications: state.chosen_notifications});
            return () => { 
                pusher.disconnect();
            };
        }  
      }, [user,state.chosen_notifications]);

    function openFavoriteDialog(favoriteName,favoritePage){
        setFavoriteName(favoriteName);
        setFavoritePage(favoritePage);
        setFavoriteVisible(true);
    }

    function confirmFavoriteDialog(){
        if(favoriteGroupValue==""){
            alert("Vyberte skupinu!");
            return;
        }
        else if(favoriteGroupValue=="nova"){
            if(favoriteValue==""){
                alert("Napíšte názov novez skupiny!");
                return;
            }
            else{
                //VYTVORIT SKUPINU AJ FAVORITA
                setFavoriteVisible(false);
                setVisible(true);
                let formData = new FormData();
                let new_group;
                formData.append("name", favoriteValue);
                postRequest(`/api/favorite_groups`, formData,true)
                .then((response) => {
                let formData2 = new FormData();
                formData2.append("name", favoriteName);
                formData2.append("page", favoritePage);
                formData2.append("favorite_group_id", response.data.id);
                postRequest(`/api/favorites`, formData2,true)
                .then((response) => {
                    getRequest("/api/my_favorite_groups").then((response) => {
                        dispatch({type:'load_favorites',newFavorites:response.data});
                        setVisible(false);
                    });
                }).catch((reason) => {
                    setVisible(false);
                    if(!navigator.online){
                        alert("Ste offline, dáta budu odoslané po znovupripojení!");
                    }
                    else{
                        alert("Daný úkon sa nepodaril!");
                    }
                })
                }).catch((reason) => {
                    setVisible(false);
                    if(!navigator.online){
                        alert("Ste offline, dáta budu odoslané po znovupripojení!");
                    }
                    else{
                        alert("Daný úkon sa nepodaril!");
                    }
                })
                setFavoriteName("");
                setFavoritePage("");
                setFavoriteGroupValue("");
                setFavoriteValue("");
            }
        }
        else{
            //VYTVORIT FAVORITA a pridat do skupiny
            setFavoriteVisible(false);
            setVisible(true);
            let formData2 = new FormData();
            formData2.append("name", favoriteName);
            formData2.append("page", favoritePage);
            formData2.append("favorite_group_id", favoriteGroupValue);
            postRequest(`/api/favorites`, formData2,true)
            .then((response) => {
                getRequest("/api/my_favorite_groups").then((response) => {
                    dispatch({type:'load_favorites',newFavorites:response.data});
                    setVisible(false);
                });
            }).catch((reason) => {
                setVisible(false);
                if(!navigator.online){
                    alert("Ste offline, dáta budu odoslané po znovupripojení!");
                }
                else{
                    alert("Daný úkon sa nepodaril!");
                }
            })
            setFavoriteName("");
            setFavoritePage("");
            setFavoriteGroupValue("");
            setFavoriteValue("");
        }
    }

    function setFastView(page_name,columns=null,attributes=null){
        setDialogText("Nastavujem");
        setVisible(true);
        let formData = new FormData();
        formData.append("page", page_name);
        formData.append("columns", JSON.stringify(columns));
        formData.append("attributes", JSON.stringify(attributes));
        postRequest(`/api/fast_views`, formData,true)
        .then((response) => {
            if(response.status == 201){
              dispatch({type:'load_fast_view',newFastView:response.data});
              setVisible(false);
              toast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Rýchly náhľad uložený!" });
            }
        }).catch((reason) => {
          console.log(reason);
          setVisible(false);
          alert("Daný úkon sa nepodaril!");
        });
      }

      function setUserMaskFilter(page_name,type,columns=null,filters=null,attributes=null){
        setDialogText("Nastavujem");
        setVisible(true);
        let formData = new FormData();
        formData.append("page", page_name);
        formData.append("type", type);
        formData.append("columns", JSON.stringify(columns));
        formData.append("filters", JSON.stringify(filters));
        formData.append("attributes", JSON.stringify(attributes));
        postRequest(`/api/user_mask_filters`, formData,true)
        .then((response) => {
            if(response.status == 201){
              dispatch({type:'load_user_mask_filters',newUserMaskFilters:response.data});
              setVisible(false);
              toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Maska uložená!"});
            }
        }).catch((reason) => {
          console.log(reason);
          setVisible(false);
          alert("Daný úkon sa nepodaril!");
        });
      }

    function getUserColor(username) {
        // Step 1: Convert username to a number
        let sumAscii = 0;
        for (let i = 0; i < username.length; i++) {
            sumAscii += username.charCodeAt(i);
        }
        
        // Step 2: Generate RGB values based on the sum
        let r = (sumAscii * 37) % 256;
        let g = (sumAscii * 59) % 256;
        let b = (sumAscii * 83) % 256;
        
        // Step 3: Convert RGB values to hex format
        let color = `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
        return color;
    }

    function getUserInitials(username){
        const all_names = username.trim().split(' ');
        return all_names.length == 3 ? all_names.reduce((acc, curr, index) => {
            if(index === all_names.length - 2 || index === all_names.length - 1){
                acc = `${acc}${curr.charAt(0).toUpperCase()}`;
            }
            return acc;
        }, '') 
        : all_names.reduce((acc, curr, index) => {
            if(index === 0 || index === all_names.length - 1){
            acc = `${acc}${curr.charAt(0).toUpperCase()}`;
            }
            return acc;
        }, '');
    }

    function setViewedNotification(notification){
        let remaining_notifications = state.notifications.filter(notif => notif.id != notification.id); 
        dispatch({type:'clear_notification',value:remaining_notifications});
        let formData = new FormData();
        formData.append("notification_id", notification.id);
        postRequest(`/api/viewed_notifications`, formData,true)
        .then((response) => {
            if(response.status == 201){
            }
        }).catch((reason) => {
          /*console.log(reason);
          setVisible(false);
          alert("Daný úkon sa nepodaril!");*/
        });
    }

    function setViewedNotifications(){
        dispatch({type:'clear_notification',value:[]});
        let formData = new FormData();
        state.notifications.map((notif) => formData.append("notifications[]", notif.id));
        postRequest(`/api/viewed_notifications/multiple`, formData,true)
        .then((response) => {
            if(response.status == 201){
            }
        }).catch((reason) => {
          /*console.log(reason);
          setVisible(false);
          alert("Daný úkon sa nepodaril!");*/
        });
    }


  return (<>
    <UtilContext.Provider value={{visible,setDialogText,setVisible,openFavoriteDialog,toast,setVisibleNotifications,setFastView,setUserMaskFilter,getUserColor,getUserInitials}}>
    <Toast ref={toast} position={toastPosition}/>
      {children}
        <ConfirmDialog/>
        
        <Dialog visible={visible} style={{ width: '25vw',height:'25vh',backgroundColor: 'transparent',boxShadow: 'none'}} className='mypanel flex flex-row'  closable={false} draggable={false} showHeader={false}>
            <InfinitySpin 
            width='208'
            color="#000"
            />
            <p className='text-2xl font-bold text-center w-52 text-black'>{dialogText}...</p>
        </Dialog>
        <Dialog header="Pridať do skupiny:" visible={favoriteVisible} style={{ width: '40vw' }} onHide={() =>  setFavoriteVisible(false)}>
        <div className="flex flex-columns">
          <div className="flex-row">
          <select 
          value={favoriteGroupValue} onChange={e => setFavoriteGroupValue(e.target.value)}
          className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
            <option  value="">Vyberte možnosť</option> 
            {state.favorites?.map((item) => (
              <option key={item.id} value={item.id}>{item.name}</option>
            ))}  
            <option  value="nova">Vytvoriť novú</option>               
          </select>
          {favoriteGroupValue=="nova" && <input
                    value={favoriteValue} onChange={e => setFavoriteValue(e.target.value)}
                    type="text"
                    placeholder="Názov skupiny..."
                    className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                />}
                </div>
          <button onClick={() => confirmFavoriteDialog()} className='w-22 h-10 ml-8 mt-2 px-4 py-2 text-center tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'>ULOŽIŤ</button>
        </div>
      </Dialog>
      <Sidebar visible={visibleNotifications} position="right" onHide={() => setVisibleNotifications(false)}>
        <div className="flex flex-row">
            <h2 className="font-semibold text-2xl pb-2 mr-4">Notifikácie</h2>
            <button onClick={() => setViewedNotifications()} className="w-40 h-8 bg-red-600 rounded-md border-red-600 font-semibold text-white hover:bg-red-500 focus:outline-none focus:bg-red-500 disabled:opacity-25" disabled={state.notifications.length == 0 ? true : false}><div className="flex justify-center items-center"><DeleteIcon className="mr-2"/>Vymazať</div></button>
        </div>
        <div className="card flex flex-col justify-content-start">
            {state.notifications.map((notification,key) => <Message severity="info" style={{
                    border: 'solid #696cff',
                    borderWidth: '0 0 0 6px',
                    color: '#696cff'
                }}
                className="border-primary w-full justify-content-start mb-2"
                content={
                    <div className="flex flex-col align-items-start">
                    <div className="flex flex-row">
                        <span className="font-semibold ml-2">{JSON.parse(notification?.data?.data || notification?.data).module.toUpperCase()}</span>
                        <span className="flex justify-end w-full font-semibold ml-2"><button onClick={() => setViewedNotification(notification)} type="button"><CloseMess/></button></span>
                    </div>
                        <div className="ml-2">{notification.message}</div>
                    </div>
                }/>)}
        </div>
      </Sidebar>
    </UtilContext.Provider>
    </>
  );
};

export default UtilContext;