import React,{useContext,useEffect, useState, useRef} from 'react';
import { DatabaseContext } from '../../redux/DatabaseStore';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from "primereact/checkbox";
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { getRequest, postRequest } from '../../api/ApiController';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { SaveIcon, AddIcon, EditIcon, CopyIcon, DeleteIcon, StepBackIcon, StepForwardIcon, PrintIcon, SearchIcon, ExportIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, SaveAndCloseIcon} from "../svg/svgIcons";
import {useReactToPrint} from "react-to-print";
import EightDreport from "../print/EightDreport";
import { Dialog } from 'primereact/dialog';
import { InfinitySpin } from  'react-loader-spinner';

export default function SupplierAdd({supplier,type}) {

    const [state,dispatch] = useContext(DatabaseContext);
    const permissions = [
        {name: 'Povolenia OTK',view:{name:'permissions',value:'access otk'},add:{name:'permissions',value:'add otk'},edit:{name:'permissions',value:'edit otk'},delete:{name:'permissions',value:'delete otk'}},
        {name: 'Povolenia Lakovňa',view:{name:'permissions',value:'access painting'},add:{name:'permissions',value:'add painting'},edit:{name:'permissions',value:'edit painting'},delete:{name:'permissions',value:'delete painting'}},
        {name: 'Povolenia Školenia',view:{name:'permissions',value:'access training'},add:{name:'permissions',value:'add training'},edit:{name:'permissions',value:'edit training'},delete:{name:'permissions',value:'delete training'}},
        {name: 'Povolenia Údržba',view:{name:'permissions',value:'access maintenance'},add:{name:'permissions',value:'add maintenance'},edit:{name:'permissions',value:'edit maintenance'},delete:{name:'permissions',value:'delete maintenance'}},
        {name: 'Povolenia Nastavenia',view:{name:'permissions',value:'access settings'},add:{name:'permissions',value:'add settings'},edit:{name:'permissions',value:'edit settings'},delete:{name:'permissions',value:'delete settings'}},
        {name: 'Povolenia Používatelia',view:{name:'permissions',value:'access users'},add:{name:'permissions',value:'add users'},edit:{name:'permissions',value:'edit users'},delete:{name:'permissions',value:'delete users'}},
    ];

    const [formSupplier,setFormSupplier] = useState(supplier);
    const [supplierType, setSupplierType] = useState(type);

    const [stepBackIcon,setStepBackIcon] = useState(true);
    const [scrollIcons,setScrollIcons] = useState(true);

    const [visibleLoading,setVisibleLoading] = useState(false);
    const [favoriteVisible,setFavoriteVisible] = useState(false);
    const [favoriteGroupValue,setFavoriteGroupValue] = useState("");
    const [favoriteValue,setFavoriteValue] = useState("");

    const [closeTab,setCloseTab] = useState(false);

    const toast = useRef(null);
    const submitRef = useRef(null);
    const componentRef = useRef(null);
    const handlePrint = useReactToPrint({
        onBeforeGetContent: () => {componentRef.current.style.display = "block";},
        onAfterPrint: () => {componentRef.current.style.display = "none";},
        content: () => componentRef.current,
    });

    useEffect(() => {
        // Update the document title using the browser API
        if(supplierType=="edit"){
            setScrollIcons(false);
        }
    },[supplierType]);

    /* validate form */
    const validate = values => {
        const errors = {};
        /*if (!values.type) {
            errors.type = '*Povinné';
        }*/
        return errors;
    };

    /* form for adding error record */
    const formik = useFormik({
        initialValues: formSupplier==null?{
            id: '',name:'',description:'',defined_id:'',offender_id:'',
        }:formSupplier,
        enableReinitialize:true,
        validate,
        onSubmit: values => {
            console.log("FORM VALUES",values);
            console.log("EMP VALUES",formSupplier);
            let formData = new FormData();
            for (let value in values) {
                formData.append(value, values[value]);
            }
            if(supplierType=="add"){
                postRequest('/api/suppliers', formData,true)
                .then((response) => {
                    console.log(response);
                    getRequest("/api/suppliers").then((response) => {
                        dispatch({type:'load_suppliers',newSuppliers:response.data});
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        dispatch({type:'suppliers_reload',value:true});
                        dispatch({type:'suppliers_reload',value:false});
                        if(response.data.length-1>=0)formik.setValues(response.data[response.data.length-1]);
                        setSupplierType("edit");
                        toast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                    });
                }).catch((reason) => {
                    if(!navigator.online){
                        alert("Ste offline, dáta budu odoslané po znovupripojení!");
                    }
                    else{
                        alert("Daný úkon sa nepodaril!");
                    }
                })
            }
            else{
                postRequest(`/api/suppliers/update/${formik.values.id}`, formData,true)
                .then((response) => {
                    getRequest("/api/suppliers").then((response) => {
                        dispatch({type:'load_suppliers',newSuppliers:response.data});
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        dispatch({type:'suppliers_reload',value:true});
                        dispatch({type:'suppliers_reload',value:false});
                        if(response.data.length-1>=0)formik.setValues(response.data[response.data.length-1]);
                        formik.setValues(values);
                        toast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne upravený!" });
                    });
                }).catch((reason) => {
                    if(!navigator.online){
                        alert("Ste offline, dáta budu odoslané po znovupripojení!");
                    }
                    else{
                        alert("Daný úkon sa nepodaril!");
                    }
                })
            }
        },
    });


    function addNewSupplier(){
        dispatch({type:'create_new_tab',name:'Nový dodávateľ',page:'SupplierAdd',props:{supplier: null,type:'add'},mask:'glo008'})
    }

    function copySupplier(){
        dispatch({type:'create_new_tab',name:'Nový dodávateľ',page:'SupplierAdd',props:{supplier: formik.values,type:'copy'},mask:'glo008'})
    }

    function getIndex(supplier_id) {
        return state.suppliers.findIndex(obj => obj.id === supplier_id);
    }

    async function scrollToFirst(){
        let supplier = state.suppliers[0];
        setFormSupplier(supplier);
    }
    
    async function scrollUp(){
        let id = getIndex(formik.values.id);
        if(id-1>=0){
            setFormSupplier(state.suppliers[id-1]);
        }
    }
    
    async function scrollDown(){
        let id = getIndex(formik.values.id);
        if(id+1<=state.suppliers.length-1){
            setFormSupplier(state.suppliers[id+1]);
        }
    }
    
    async function scrollToLast(){
        let last = state.suppliers.length-1;
        let supplier = state.suppliers[last];
        setFormSupplier(supplier);
    }

    function confirmFavoriteDialog(){
        if(favoriteGroupValue==""){
          alert("Vyberte skupinu!");
          return;
        }
        else if(favoriteGroupValue=="nova"){
          if(favoriteValue==""){
            alert("Napíšte názov novez skupiny!");
            return;
          }
          else{
            //VYTVORIT SKUPINU AJ FAVORITA
            setFavoriteVisible(false);
            setVisibleLoading(true);
            let formData = new FormData();
            formData.append("name", favoriteValue);
            postRequest(`/api/favorite_groups`, formData,true)
            .then((response) => {
              let formData2 = new FormData();
              formData2.append("name", "Nový dodávateľ");
              formData2.append("page", "SupplierAdd");
              formData2.append("favorite_group_id", response.data.id);
              postRequest(`/api/favorites`, formData2,true)
              .then((response) => {
                  getRequest("/api/my_favorite_groups").then((response) => {
                    dispatch({type:'load_favorites',newFavorites:response.data});
                    setVisibleLoading(false);
                });
              }).catch((reason) => {
                  setVisibleLoading(false);
              })
            }).catch((reason) => {
                setVisibleLoading(false);
            })
          }
        }
        else{
          //VYTVORIT FAVORITA a pridat do skupiny
          setFavoriteVisible(false);
          setVisibleLoading(true);
          let formData2 = new FormData();
          formData2.append("name", "Nový dodávateľ");
          formData2.append("page", "SupplierAdd");
          formData2.append("favorite_group_id", favoriteGroupValue);
          postRequest(`/api/favorites`, formData2,true)
          .then((response) => {
              getRequest("/api/my_favorite_groups").then((response) => {
                dispatch({type:'load_favorites',newFavorites:response.data});
                setVisibleLoading(false);
            });
          }).catch((reason) => {
              setVisibleLoading(false);
          })
        }
    }

    return (
        <div>
        <div className='w-full h-10 flex flex-row bg-[#333]'>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button type='submit' onClick={() => {setCloseTab(true);submitRef.current.click();}}><SaveAndCloseIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button type='submit' onClick={() => {submitRef.current.click();}}><SaveIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button onClick={() => addNewSupplier()}><AddIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button onClick={() => copySupplier()}><CopyIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="opacity-25"><PrintIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollToFirst()} disabled={scrollIcons}><BackToFirstIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollUp()} disabled={scrollIcons}><BackIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollDown()} disabled={scrollIcons}><ForwardIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollToLast()} disabled={scrollIcons}><ForwardToLastIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button onClick={() => setFavoriteVisible(true)}><FavoriteIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button><PriceIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
        </div>
        
        <div className="relative flex flex-col justify-left overflow-x min-h-[calc(100vh-177px)]">
          <div className=" mb-2 ">
          <form onSubmit={formik.handleSubmit}>
            <div className="w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md">
                <h1 className="text-3xl font-semibold text-left text-zinc-900">
                   DODÁVATEĽ
                </h1>
                {supplierType=="edit" && <label className="block text-md font-semibold text-gray-800">Por.č.: {formik.values.id}</label>}
                <div className="mt-6">
                    <div className="md:grid grid-cols-2 gap-1 mb-2">
                      <div>
                        <label
                            htmlFor="name"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Meno dodávateľa
                        </label>
                        <input
                            name='name'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.name}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="description"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Popis dodávateľa
                        </label>
                        <input
                            name='description'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.description}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                    </div>
                    <div className="md:grid grid-cols-2 gap-4 mb-2">
                    <div>
                        <label
                            htmlFor="defined_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Priradené ID
                        </label>
                        <input
                            name='defined_id'
                            type="number"
                            onChange={formik.handleChange} value={formik.values.defined_id}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                    </div>
                </div>  
                <button type="submit" ref={submitRef} className='hidden'></button>
            </div> 
                </form>       
            {/*<div className="w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md">
                <h1 className="text-xl font-semibold text-left text-zinc-900">
                Priradenie povolení
                </h1>
                <div className="mt-6">
                    <div className="md:w-auto mb-2">
                      <div className="mb-4">
                        <label
                            htmlFor="artikel"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Meno
                        </label>
                        <input
                            type="text"
                            className="block w-auto  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div className="mb-4">
                        <label
                            htmlFor="nazov_artikla"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Slug
                        </label>
                        <input
                            type="text"
                            className="block w-fit px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                    </div>
                    <div>   
                    <DataTable value={permissions} tableStyle={{ minWidth: '50rem' }} size="small">
                        <Column field="name" header=""></Column>
                        <Column field="view" header="Prezerať" body={accessTemplate}></Column>
                        <Column field="add" header="Vkladať" body={addTemplate}></Column>
                        <Column field="edit" header="Upravovať" body={editTemplate}></Column>
                        <Column field="delete" header="Mazať" body={deleteTemplate}></Column>
                          </DataTable>
                          </div>
                    
                </div>
                
                <div>
                <button type="submit" className='w-full font-medium px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'>AKTUALIZOVAŤ ROLU</button>
            </div>
            </div>           
            </div>*/}
            </div>
            <div>
            <input
                name='type'
                type="hidden"
                value={formik.values.type}
            />
            {/*<button type="submit" className='w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'>ULOŽIŤ</button>*/}
            </div>
        </div>
        <Toast ref={toast} />
        <Dialog header="Pridať do skupiny:" visible={favoriteVisible} style={{ width: '40vw' }} onHide={() => setFavoriteVisible(false)}>
        <div className="flex flex-columns">
          <div className="flex-row">
          <select 
          value={favoriteGroupValue} onChange={e => setFavoriteGroupValue(e.target.value)}
          className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
            <option  value="">Vyberte možnosť</option> 
            {state.favorites.map((item) => (
              <option key={item.id} value={item.id}>{item.name}</option>
            ))}  
            <option  value="nova">Vytvoriť novú</option>               
          </select>
          {favoriteGroupValue=="nova" && <input
                    value={favoriteValue} onChange={e => setFavoriteValue(e.target.value)}
                    type="text"
                    placeholder="Názov skupiny..."
                    className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                />}
                </div>
          <button onClick={() => confirmFavoriteDialog()} className='w-22 h-10 ml-8 mt-2 px-4 py-2 text-center tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'>ULOŽIŤ</button>
        </div>
        </Dialog>
        <Dialog visible={visibleLoading} style={{ width: '25vw',height:'25vh',backgroundColor: 'transparent',boxShadow: 'none'}} className='mypanel flex flex-row'  closable={false} draggable={false} showHeader={false}>
            <InfinitySpin 
            width='208'
            color="#000"
            />
            <p className='text-2xl font-bold text-center w-52 text-black'>Načítavam...</p>
            </Dialog>
        </div>
    );
}