import React,{useContext,useEffect, useState,useRef} from 'react';
import { getRequest, postRequest, putRequest,deleteRequest } from '../../api/ApiController';
import { DatabaseContext } from '../../redux/DatabaseStore';
import { ReactTabulator,reactFormatter } from 'react-tabulator';
import '../../assets/css/style.css';
import '../../assets/css/iconsmenu.css'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css"
import { AddIcon, EditIcon, CopyIcon, DeleteIcon, StepBackIcon, PrintIcon, SearchIcon, ExportIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon,EditRowIcon} from "../svg/svgIcons";
import {useReactToPrint} from "react-to-print";
import { InfinitySpin } from  'react-loader-spinner';
import { DateTime } from "luxon";

export default function DepartmentList(){

  const [state,dispatch] = useContext(DatabaseContext);

  const [stepBackIcon,setStepBackIcon] = useState(true);
  const [editIcon,setEditIcon] = useState(true);
  const [deleteIcon,setDeleteIcon] = useState(true);
  const [favoriteIcon,setFavoriteIcon] = useState(false);
  const [copyIcon,setCopyIcon] = useState(true);

  const [visibleSearch,setVisibleSearch] = useState(false);
  const [visibleLoading,setVisibleLoading] = useState(false);
  const [searchValue,setSearchValue] = useState('');
  const [scrollValue,setScrollValue] = useState(1);
  const [favoriteVisible,setFavoriteVisible] = useState(false);
  const [favoriteGroupValue,setFavoriteGroupValue] = useState("");
  const [favoriteValue,setFavoriteValue] = useState("");

    const [editFormDepartment, setEditFormDepartment] = useState([]);

    const ref = useRef(null);
    const toast = useRef(null);
    const elementRef = useRef(null);
    const componentRef = useRef(null);
    const handlePrint = useReactToPrint({
        onBeforeGetContent: () => {componentRef.current.style.display = "block";},
        onAfterPrint: () => {componentRef.current.style.display = "none";},
        content: () => componentRef.current,
    });

    useEffect(() => {
      // Update the document title using the browser API
      const isFound = state.favorites.some(favorite => {
        if (favorite.page === 'EmployeeList') {
          return true;
        }
        return false;
      });
      
      if (isFound) {
        setFavoriteIcon(true);
      }
    });

    useEffect(() => {
      if (state.departments && ref.current && state.departments_reload) {
        ref.current.updateData(state.departments);
        //lepsie vymysliet reload
        //dat hook na to
      }
    },[state.departments,ref.current,state.departments_reload]);
  
  
  function matchAny(data, filterParams) {
    //data - the data for the row being filtered
    //filterParams - params object passed to the filter
    //RegExp - modifier "i" - case insensitve

    var match = false;
    const regex = RegExp(filterParams.value, 'i');

    for (var key in data) {
        if (regex.test(data[key]) === true) {
            match = true;
        }
    }
    return match;
  }

  function searchTable(){
    ref.current.setFilter(matchAny, { value: searchValue});
    if (searchValue === " ") {
      ref.current.clearFilter()
    }
    setVisibleSearch(false);
  }

  const accept = () => {
    let rows_to_delete = [];
    rows_to_delete = ref.current.getSelectedData();
    rows_to_delete.forEach(element => {
      console.log(element);
      deleteRequest(`/api/departments/${element.id}`)
          .then((response) => {
            toast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne vymazaný!" });
            getRequest("/api/departments").then((response) => {
              dispatch({type:'load_departments',newDepartments:response.data});
              dispatch({type:'departments_reload',value:true});
              dispatch({type:'departments_reload',value:false});
          });
          }).catch((reason) => {
              alert(reason);
              toast.current.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Záznam sa nepodarilo vymazať!', life: 3000 });
          })
    });
    getRequest("/api/departments").then((response) => {
      dispatch({type:'load_departments',newDepartments:response.data});
    });   
    //toast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne vymazaný!" });
}

const reject = () => {
    toast.current.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavka bola zrušená!', life: 3000 });
}

const confirm = () => {
    confirmDialog({
        message: 'Prajete si vymazať tento záznam?',
        header: 'Potvrdenie vymazania',
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        acceptLabel: 'Áno',
        rejectLabel: 'Nie',
        accept,
        reject
    });
};

const rowClick = (e, row) => {
console.log(e, row);
};

const onSelectRow = (data, rows) => {
setEditFormDepartment(data);
  if(data.length === 0){
    setEditIcon(true);
    setCopyIcon(true);
    setDeleteIcon(true);
  }
  else{
    setEditIcon(false);
    setCopyIcon(false);
    setDeleteIcon(false);
  }
};

function onEditRow(department){
  dispatch({type:'create_new_tab',name:'Upraviť oddelenie',page:'DepartmentAdd',props:{department: department,type:'edit'},mask:'glo002'})
};

function EditRowFormatter(props) {
  const rowData = props.cell._cell.row.data;
  return <div className='flex items-center justify-center w-full h-full'><button type='button' onClick={() => onEditRow(rowData)}><EditRowIcon/></button></div>;
}

  const columns = [
    { formatter:"rowSelection", titleFormatter:"rowSelection", hozAlign:"center",width: 20,headerSort:false},
    { hozAlign:"center",width: 20,headerSort:false,formatter: reactFormatter(<EditRowFormatter/>),resizable:false},
    { title: 'Názov oddelenia', field: 'name',editor: "input",headerFilter:"input"},
    { title: 'Hod. sadzba', field: 'hourly_rate',editor: "input",headerFilter:"input" },
    { title: 'Číslo oddelenia', field: 'defined_id',editor: "input",headerFilter:"input" },
  ];


function addNewDepartment(){
  dispatch({type:'create_new_tab',name:'Nové oddelenie',page:'DepartmentAdd',props:{department: null,type:'add'},mask:'glo002'})
}
function copyDepartment(){
  dispatch({type:'create_new_tab',name:'Nové oddelenie',page:'DepartmentAdd',props:{department: ref.current.getSelectedData()[0],type:'copy'},mask:'glo002'})
}
function editDepartment(){
  dispatch({type:'create_new_tab',name:'Upraviť oddelenie',page:'DepartmentAdd',props:{department: ref.current.getSelectedData()[0],type:'edit'},mask:'glo002'})
}

function confirmFavoriteDialog(){
  if(favoriteGroupValue==""){
    alert("Vyberte skupinu!");
    return;
  }
  else if(favoriteGroupValue=="nova"){
    if(favoriteValue==""){
      alert("Napíšte názov novez skupiny!");
      return;
    }
    else{
      //VYTVORIT SKUPINU AJ FAVORITA
      setFavoriteVisible(false);
      setVisibleLoading(true);
      let formData = new FormData();
      let new_group;
      formData.append("name", favoriteValue);
      postRequest(`/api/favorite_groups`, formData,true)
      .then((response) => {
        let formData2 = new FormData();
        formData2.append("name", "Oddelenia");
        formData2.append("page", "DepartmentList");
        formData2.append("favorite_group_id", response.data.id);
        postRequest(`/api/favorites`, formData2,true)
        .then((response) => {
            getRequest("/api/my_favorite_groups").then((response) => {
              dispatch({type:'load_favorites',newFavorites:response.data});
              setVisibleLoading(false);
          });
        }).catch((reason) => {
            setVisibleLoading(false);
            if(!navigator.online){
                alert("Ste offline, dáta budu odoslané po znovupripojení!");
            }
            else{
                alert("Daný úkon sa nepodaril!");
            }
        })
      }).catch((reason) => {
          setVisibleLoading(false);
          if(!navigator.online){
              alert("Ste offline, dáta budu odoslané po znovupripojení!");
          }
          else{
              alert("Daný úkon sa nepodaril!");
          }
      })
    }
  }
  else{
    //VYTVORIT FAVORITA a pridat do skupiny
    setFavoriteVisible(false);
    setVisibleLoading(true);
    let formData2 = new FormData();
    formData2.append("name", "Oddelenia");
    formData2.append("page", "DepartmentList");
    formData2.append("favorite_group_id", favoriteGroupValue);
    postRequest(`/api/favorites`, formData2,true)
    .then((response) => {
        getRequest("/api/my_favorite_groups").then((response) => {
          dispatch({type:'load_favorites',newFavorites:response.data});
          setVisibleLoading(false);
      });
    }).catch((reason) => {
        setVisibleLoading(false);
        if(!navigator.online){
            alert("Ste offline, dáta budu odoslané po znovupripojení!");
        }
        else{
            alert("Daný úkon sa nepodaril!");
        }
    })
  }
}

async function scrollToFirst(){
  let res = state.departments[0].id;
  ref.current.scrollToRow(res, "top", true);
  setScrollValue(1);
}

async function scrollUp(){
  let visible_rows = Math.floor((elementRef.current.clientHeight-68)/30-1);
  if((scrollValue-visible_rows+1) >= 0){
    let res = state.departments[scrollValue-visible_rows+1].id;
    ref.current.scrollToRow(res, "top", true);
    setScrollValue(scrollValue-visible_rows);
  }
  else if(scrollValue > 0 && scrollValue <= visible_rows){
    let res = state.departments[0].id;
    ref.current.scrollToRow(res, "top", true);
    setScrollValue(1);
  }
}

async function scrollDown(){
  let visible_rows = Math.floor((elementRef.current.clientHeight-68)/30-1);
  let rows = ref.current.getRows(true).length;
  if(scrollValue===1){
    let res = state.departments[scrollValue+visible_rows-1].id;
    ref.current.scrollToRow(res, "top", true);
    setScrollValue(scrollValue+visible_rows);
  }
  else if((scrollValue+visible_rows) <= rows){
    let res = state.departments[scrollValue+visible_rows-1].id;
    let rows = ref.current.getRows(true).length;
    setScrollValue(scrollValue+visible_rows);
    if(res > rows-visible_rows){
      res = state.departments[rows-visible_rows].id;
      setScrollValue(rows-visible_rows);
    }
    ref.current.scrollToRow(res, "top", true);
  }
}

async function scrollToLast(){
  let visible_rows = Math.floor((elementRef.current.clientHeight-68)/30-1);
  let rows = ref.current.getRows(true).length;
  let res = state.departments[rows-visible_rows].id;
  ref.current.scrollToRow(res, "top", true);
  setScrollValue(rows-visible_rows);
}


    return(
        <>

    <div className='w-full h-10 flex flex-row bg-[#333]'>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button onClick={() => addNewDepartment()}><AddIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25" disabled={editIcon} onClick={() => editDepartment()}><EditIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25" disabled={copyIcon} onClick={() => copyDepartment()}><CopyIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25" onClick={confirm} disabled={deleteIcon}><DeleteIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button onClick={() => ref.current.print(false, true)}><PrintIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button onClick={() => setVisibleSearch(true)}><SearchIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button onClick={() => ref.current.download("xlsx", "data.xlsx", {sheetName:"MyData"})}><ExportIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><VerticalLineIcon/></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button onClick={() => scrollToFirst()}><BackToFirstIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button onClick={() => scrollUp()}><BackIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button onClick={() => scrollDown()}><ForwardIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button onClick={() => scrollToLast()}><ForwardToLastIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><VerticalLineIcon/></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25" disabled={favoriteIcon} onClick={() => setFavoriteVisible(true)}><FavoriteIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button><PriceIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><VerticalLineIcon/></div>
      </div>
<div className="flex flex-col overflow-hidden min-h-[calc(100vh-177px)]">
  <div className="sm:-mx-4 lg:-mx-6">
    <div className="inline-block w-full py-2 sm:px-6 lg:px-8">
    <div ref={elementRef}>
      <ReactTabulator
      data={state.departments} 
      className='h-[calc(100vh_-_193px)]' //176px
      onRef={(r) => (ref.current = r.current)}
      columns={columns}
      events={{
        rowClick: rowClick,
        rowSelectionChanged: onSelectRow
      }}
      options={{layout:"fitColumns",rowHeight:30,
      printAsHtml:true,printHeader:"<h1>Zoznam oddelení<h1>",printRowRange:"active",
      autoResize:true}}/> 
    </div> 
      </div>
    </div>
    <Toast ref={toast} />
          <ConfirmDialog />
          <Dialog header="Hľadaj v zozname:" visible={visibleSearch} style={{ width: '40vw' }} onHide={() => setVisibleSearch(false)}>
            <div className="flex flex-columns">
              
              <input
                  value={searchValue} onChange={e => setSearchValue(e.target.value)}
                  type="text"
                  className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
              />
              <button onClick={() => searchTable()} className='w-16 ml-8 px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'><SearchIcon/></button>
            </div>
          </Dialog>
    <Dialog header="Pridať do skupiny:" visible={favoriteVisible} style={{ width: '40vw' }} onHide={() => setFavoriteVisible(false)}>
      <div className="flex flex-columns">
        <div className="flex-row">
        <select 
        value={favoriteGroupValue} onChange={e => setFavoriteGroupValue(e.target.value)}
        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
          <option  value="">Vyberte možnosť</option> 
          {state.favorites.map((item) => (
            <option key={item.id} value={item.id}>{item.name}</option>
          ))}  
          <option  value="nova">Vytvoriť novú</option>               
        </select>
        {favoriteGroupValue=="nova" && <input
                  value={favoriteValue} onChange={e => setFavoriteValue(e.target.value)}
                  type="text"
                  placeholder="Názov skupiny..."
                  className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
              />}
              </div>
        <button onClick={() => confirmFavoriteDialog()} className='w-22 h-10 ml-8 mt-2 px-4 py-2 text-center tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'>ULOŽIŤ</button>
      </div>
    </Dialog>
      <Dialog visible={visibleLoading} style={{ width: '25vw',height:'25vh',backgroundColor: 'transparent',boxShadow: 'none'}} className='mypanel flex flex-row'  closable={false} draggable={false} showHeader={false}>
          <InfinitySpin 
          width='208'
          color="#000"
          />
          <p className='text-2xl font-bold text-center w-52 text-black'>Načítavam...</p>
      </Dialog>
  </div>
 </>
    )

}